<template>
  <div 
    ref="statsBox"
    class="border-between-section border-0 border-dashed"
    style="margin-bottom:0.3cm;"
  >
    <div class="field-container">
      <label class="input-fill">
        Dimension à comparer avec Sections du Cercle
        <select
          v-model="groupValue"
          @change="(e) => onInput(e, groupValue, true)"
        >
          <option
            v-for="(property, index) in [
              {name:'Sexe', value:'Sexe'},
              {name:'Age', value:'AgeJourExam'},
            ]"
            :key="index"
            :value="property.value"
          >
            {{ property.name }}
          </option>

        </select>
        
      </label>
      <button @click="handleSubmit">
        Valider
      </button>
    </div>
    <!-- Analyse de la distribution -->
    <div
      v-if="groupValue == 'AgeJourExam'"
    >
      <div 
        class="input-row"
      >
        <label for="n">Nombre de tranches:</label>
        <input 
          v-model="n" 
          type="number" 
          min="1" 
          placeholder="Enter positive integer" 
          class="small-input"
          @input="(e) => onInput(e, n, false)"
        >
        
        <label for="tranche">Taille:</label>
        <input 
          v-model="tranche" 
          type="number" 
          min="1" 
          placeholder="Enter tranche value" 
          class="small-input"
          @input="(e) => onInput(e, tranche, false)"
        >
        <label for="testcheck">Test Check:</label>
        <input 
          v-model="testcheck" 
          type="checkbox"
          @click="onClickCheckbox"
        >
      </div>
    </div>

    <h3 v-if="stats">
      <i>{{ groupValue }}</i>:
    </h3>
    <div 
      ref="chartAge" 
      class="chartAge"
    />

    <!-- χ² -->
    <div v-if="stats && stats.chi2">
      <p><strong>Chi-squared:</strong> {{ stats.chi2 }}</p>
      <p><strong>P-value:</strong> {{ stats.p_value }}</p>
      <p><strong>Degrees of Freedom:</strong> {{ stats.degrees_of_freedom }}</p>

      <Collapse>
        <template #title>
          <h3>Expected Frequencies:</h3>
        </template>

        <template #content>
          <table 
            cellspacing="0"
            cellpadding="5"
          >
            <tbody>
              <tr 
                v-for="(row, rowIndex) in stats.expected_frequencies" 
                :key="rowIndex"
              >
                <td>Row {{ rowIndex + 1 }}</td>
                <td>[ {{ row.join(', ') }} ]</td>
              </tr>
            </tbody>
          </table>
        </template>
      </Collapse>
    </div>  

    <label>en nombre d'evenement:</label>
    <input 
      v-if="groupValue == 'AgeJourExam'"
      v-model="CountCheck" 
      type="checkbox"
      @click="onClickCheckbox"
    >

    <div>
      <div 
        ref="chart_moustaches" 
        class="chart_moustaches"
      />
    </div>
  </div>
</template>

<script setup>

import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import * as eyediagModeEnum from '@/shared/enums/eyediagMode.js'
import EyeFetch from '@/libraries/EyeFetch'
import AppContextChecker from "@/libraries/AppContextChecker.js";
import { ageRangePlot } from './PlotUtils_List';
import BorderListTypes from '@/enums/borderList/borderListTypes'
import * as d3 from "d3";
import Collapse from '@/components/Collapse.vue'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'
import { moustaches_sev } from '@/components/tooltip/PlotsUtils';

const groupTypes = [
  { label: 'Section', value: eyediagModeEnum.populationalOptions.mergedBy.SECTION },
  { label: 'Evénement', value: eyediagModeEnum.populationalOptions.mergedBy.EVENT }
]
let n = 5 
let tranche = 5 
let testcheck = true
let CountCheck = true

const store = useStore()
const allEvents = computed(() => store.getters['event/common/allEvents'])
const currentDisplayedBorderList = computed(() => store.getters['borderList/currentDisplayedBorderList'])
const Sections = computed(() => store.getters['sections'])
const typeEntityContext = computed(() => store.getters['user/typeEntityContext'])

let CodeRatio = ref('')
const groupValue = ref('')
const chartAge = ref(null);
const chart_moustaches = ref(null)
const stats = ref('');
const statsBox = ref('')

let mesEvents = allEvents.value
let resizeObserver = null
onMounted(() => {
  resizeObserver = new ResizeObserver(() => {
    if (stats.value.distribution) {
      drawChart(stats.value.distribution);
    }
  });
  resizeObserver.observe(chartAge.value);
  if (stats.value.distribution) {
    drawChart(stats.value.distribution);
  }
})


const handleSubmit = async (event) => {
  store.dispatch('ws/sendEvent', { event: event })
  // let mesEvents = allEvents.value
  if (AppContextChecker.isMergedEvents(store)) {
    mesEvents = []
    allEvents.value.forEach(event => {
      event.ranks.forEach(rank => {
        rank.events.forEach(rankEvent =>
          mesEvents.push(rankEvent)
        )
      })
    })
    mesEvents = mesEvents.map(item => {
      const replacement = Sections.value.find(repl => repl.id === item.idSection);
      return {
        ...item,
        idSection: replacement ? replacement.name : item.idSection
      };
    });
  }
  
  const EventFiltered = computed(() => mesEvents
    .map(event => ({
      id: event.extension.IdPatient || event.id,
      onsetDateTime: event.onsetDateTime,
      idSection: event.idSection,
      Section: event.SectionName,
      code: event.code,
      dimension: event.extension[groupValue.value] || event[groupValue.value] ||  null,
    }))
  )
  
  if (EventFiltered.value[0]?.dimension) {
    
    const statsOptions = {
      Event: EventFiltered.value,
      Setting: {
        type: groupValue.value,
        test: testcheck? 'bySection': 'occurence',
        n: n,
        age_range: tranche,
      }
    }
    try {
      const response = await EyeFetch(store, `${process.env.VUE_APP_SERVER_BASE_URL}/services/stats_data`, {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify(
          statsOptions
        )
      })
      const res = await response.json()
      stats.value = JSON.parse(res);
      store.dispatch('ws/collaborativeEventTreated')
      return { stats };
    } catch (error) {
      console.error('Error making the POST request:', error)
    }
  }
  else {
    console.warn('No dimension selected in the first filtered event.');
  }
  store.dispatch('ws/collaborativeEventTreated')
  return false
}

watch(stats, () => {
  if (stats.value && stats.value.distribution) {
    drawChart(stats.value.distribution)
  }
  if (AppContextChecker.isMergedEvents(store)) {
    let tempEvents = [];
    allEvents.value.forEach(event => {
      event.ranks.forEach(rank => {
        rank.events.forEach(rankEvent => {
          tempEvents.push(rankEvent);
        });
      });
    });
    
    // Replace `idSection` with the name from the `Sections` array
    tempEvents = tempEvents.map(item => {
      const replacement = Sections.value.find(repl => repl.id === item.idSection);
      return {
        ...item,
        idSection: replacement ? replacement.name : item.idSection
      };
    });
    // Set `mesEvents` to the processed data
    CodeRatio.value = tempEvents;
  } else {
    // Directly assign `allEvents` to `mesEvents`
    CodeRatio.value = allEvents.value;
  }
  const nCORO = CodeRatio.value.filter(event => event.code == "CORO").length
  const nDEPIS = CodeRatio.value.filter(event => event.code == "Depistage").length
  CodeRatio.value = (nCORO / nDEPIS).toFixed(2)
})
watch(groupValue, () => { 
  CountCheck = true
})

function drawChart(res) {
  d3.select(chartAge.value).select("svg").remove();
  if (currentDisplayedBorderList.value == BorderListTypes.LIST_GRAPHS  && stats.value.distribution) {
    ageRangePlot(res, chartAge.value, {test: testcheck? 'bySection': 'occurence', 'type':groupValue.value});
  }
  try {
    if (currentDisplayedBorderList.value == BorderListTypes.LIST_GRAPHS &&
        chart_moustaches.value
    ) {
      const filteredData =  Object.values((mesEvents || allEvents.value)
        .reduce((acc, { id, onsetDateTime, extension, code, idSection }) => {
          if (!acc[extension.IdPatient || id]) {
            acc[extension.IdPatient || id] = {
              id: extension.IdPatient || id,
              onsetDateTime: extension.DateDepistage || onsetDateTime,
              val: extension.value || extension.AgeJourExam,
              Section: idSection,
              uniqueCodes: [],
            };
          }
          if (code !== "Depistage" && CountCheck) {            
            acc[extension.IdPatient || id].uniqueCodes.push(code);
          }
          return acc;
        }, {})
      ).map(item => (
        {
          ...item,
          val: CountCheck ? item.uniqueCodes.length : item.val,
          uniqueCodes: undefined,
        } 
      ))
      
      d3.select(chart_moustaches.value).select("svg").remove();
      if (chart_moustaches.value) {
        moustaches_sev(filteredData, chart_moustaches.value, typeEntityContext, "Section")
      }
    }
  } catch (error) {
    console.error(error)
  }
  
  // function initResizeObserver() {
  //   resizeObserver = new ResizeObserver((entries) => {
  //     try {
  //       for (const entry of entries) {
  //         drawChart();
  //       }
  //     } catch (error) {
  //     }
  //   });
  //   if (statsBox.value) {
  //     resizeObserver.observe(statsBox.value);
  //   }
  // }
  // function collapsing(open) {
  //   if (open) {
  //     if (resizeObserver == null) {
  //       initResizeObserver()
  //     }
  //     drawChart()
  //   } 
  //   if (/*Object.values($refs).length == 1 && */resizeObserver) {
  //     resizeObserver.disconnect();
  //     resizeObserver = null
  //   }
  // }
}

function onClickCheckbox(event) {
  store.dispatch('ws/sendEvent', { event: event })
  store.dispatch('ws/collaborativeEventTreated')
}

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])

</script>

<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1em;
}

td {
  border: 1px solid var(--color-text);
  text-align: left;
  padding: 8px;
}

.input-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

label {
  margin-right: 5px;
}
.small-input {
  width: 30px;
}

</style>
