<template>
  <div>
    <ImportButton
      text="Importer"
      accept=".json"
      @content="onFileContent"
    />
    <DesignButton
      class="mt-2"
      text="Exporter"
      @click="onExport"
    />
  </div>
</template>

<script setup>
import DesignButton from '@/components/DesignButton.vue'
import ImportButton from '@/components/utils/ImportButton'
import utils from '@/libraries/utils.js'

const globalConfig = defineModel('globalConfig', {
  type: Object,
  required: true
})

const sectionsConfig = defineModel('sectionsConfig', {
  type: Array,
  required: true
})

function onFileContent(content) {
  const config = JSON.parse(content)

  globalConfig.value.config = config.global.config
  for (const section of sectionsConfig.value) {
    const result = config.sectionsConfig.find(c => c.section.name === section.section.name)
    if (result !== undefined) {
      console.log(section, result)
      section.config = result.config
    }
  }
}

function onExport() {
  const config = {
    global: globalConfig.value,
    sectionsConfig: sectionsConfig.value
  }
  utils.downloadObjectAsJson(config, 'configSeverite')
}
</script>