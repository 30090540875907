import CustomFilteringFormContent from '../enums/customFilteringFormContent.js'
import operatorFilterCustom from '../config/operatorFilterCustom.js'

class FilterCustom {
  constructor(events, filters, filteredEvents) {
    this.events = events
    this.filters = filters,
    this.filteredEvents = filteredEvents
  }

  static getDefaultRootTemplate() {
    return {
      operator: 'AND',
      type: 'block', 
      conditions: [{
        operator: 'AND',
        type: 'item',
        typeCondition: CustomFilteringFormContent.STRICT_VALUE,
        item: FilterCustom.getTemplateFor(CustomFilteringFormContent.STRICT_VALUE)
      }]
    }
  }

  static getTemplateFor(formContent, config = {}) {
    return operatorFilterCustom[formContent].template(config)
  }

  filterCustom() {
    if (this.filters.custom) {
      for (const event of this.events) {
        if (this.filterCustomCheckEvent(event, this.filters.custom) === true) {
          this.filteredEvents.push(event)
        }
      }
    }
  }

  filterCustomCheckEvent(event, filters) {
    if (filters.type === 'block') {
      const results = []
      const funcLogicalOperator = (filters.operator === "OR") ? 'some' : 'every' 
      for (const condition of filters.conditions) {
        results.push(this.filterCustomCheckEvent(event, condition))
      }
      return results[funcLogicalOperator](elem => elem === true)
    } else {
      return this.itemConditionChecker(event, filters)
    }
  }

  itemConditionChecker(event, filters) {
    if (operatorFilterCustom[filters.typeCondition]) {
      return operatorFilterCustom[filters.typeCondition].checker(event, filters)
    }
    return false
  }

  static setIdToSection(sections) {
    const loopSections = (srcSections, id) => {
      for (const [index, section] of srcSections.entries()) {
        section.id = id === '' ? `${index}` : `${id}-${index}`
        loopSections(section.children, section.id)
      }
    }

    loopSections(sections, '')
  }
}

export default FilterCustom