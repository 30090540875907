<template>
  <div>
    <div>
      <Collapse>
        <template #title>
          Global
        </template>
        <template #content>
          <FormConfig
            v-model="globalConfig"
          />
        </template>
      </Collapse>
    </div>
    <div
      v-for="(section, index) in sectionsConfig"
      :key="index"
      class="mt-4"
    >
      <Collapse>
        <template #title>
          {{ section.section.name }}
        </template>
        <template #content>
          <FormConfig
            v-model="sectionsConfig[index]"
          />
        </template>
      </Collapse>
    </div>
    <ImportExport
      v-model:global-config="globalConfig"
      v-model:sections-config="sectionsConfig"
      class="mt-8"
    />
    <DesignButton
      class="mt-8"
      text="Valider sévérités"
      @click="onValidateSeverities"
    />
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import Collapse from '@/components/Collapse.vue'

import FormConfig from '@/components/borderLists/ListConfigSections/configSeverities/form.vue'
import DesignButton from '@/components/DesignButton.vue'
import * as mutationTypes from '@/store/mutations-types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import utils from '@/libraries/utils.js'

import ImportExport from '@/components/borderLists/ListConfigSections/configSeverities/ImportExport.vue'

import TypeConfigCustomSeverity from '@/shared/enums/typeConfigCustomSeverity.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'

const store = useStore()

const globalConfig = ref({
  section: {
    maxSeverityValue: null,
    minSeverityValue: null
  },
  config: {
    type: TypeConfigCustomSeverity.PERCENTAGE,
    typeScale: eyediagMode.typeScale.LINEAR,
    min: 0,
    max: 0
  }
})

const sectionsConfig = ref([])
const sections = computed(() => store.getters['sections'])

onMounted(() => {
  for (const section of sections.value) {
    sectionsConfig.value.push({
      section: {id: section.id, name: section.name, maxSeverityValue: section.maxSeverityValue, minSeverityValue: section.minSeverityValue},
      config: {type: TypeConfigCustomSeverity.PERCENTAGE, typeScale: eyediagMode.typeScale.LINEAR, min: 0, max: 0}
    })

    if (section.maxSeverityValue !== null) {
      globalConfig.value.section.maxSeverityValue = Math.max(globalConfig.value.section.maxSeverityValue || section.maxSeverityValue, section.maxSeverityValue)
    }

    if (section.minSeverityValue !== null) {
      globalConfig.value.section.minSeverityValue = Math.min(globalConfig.value.section.minSeverityValue || section.minSeverityValue, section.minSeverityValue)
    }
  }
})

async function onValidateSeverities(event) {
  store.dispatch('ws/sendEvent', { event: event })
  const severitiesConfig = {
    global: globalConfig.value,
    sectionsConfig: sectionsConfig.value.filter(section => section.config.type !== TypeConfigCustomSeverity.NONE)
  }

  store.commit(`pie/${mutationTypes.SET_SEVERITIES_CONFIG}`, severitiesConfig)
  await store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: store.getters['circle/unitPerCircle'],
    periodUnit: store.getters['circle/periodUnit'],
    reloadDataTypes: reloadDataTypes.CATEGORISATION
  })
  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}

</script>