<template>
  <ListBase
    id="list-config-sections"
    ref="listBaseRef"
    title="Sections"
  >
    <template #content>
      <div>
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          @click="(e) => onClickTab(e, tab)"
        >
          {{ tab.label }}
        </button>
      </div>
      <component
        :is="currentTab.component"
        v-if="currentTab !== null"
      />
    </template>
  </ListBase>
</template>

<script setup>
import { ref, markRaw } from 'vue'
// import { useStore } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'
import ConfigSections from '@/components/borderLists/ListConfigSections/ConfigSections.vue'
import ConfigSeverities from '@/components/borderLists/ListConfigSections/ConfigSeverities.vue'

// const store = useStore()
const tabs = [
  {
    label: 'Sections perso',
    component: markRaw(ConfigSections)
  },
  {
    label: 'Sévérités',
    component: markRaw(ConfigSeverities)
  }
]

const currentTab = ref(null)
const listBaseRef = ref(null)

function movePanel(...params) {
  listBaseRef.value.movePanel(...params)
}

function onClickTab(event, tab) {
  currentTab.value = tab
}

defineExpose({
  movePanel: movePanel
})
</script>