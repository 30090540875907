import CustomFilteringFormContent from "@/shared/enums/customFilteringFormContent.js"
import EventsUtils from "@/shared/libraries/eventsUtils.js"

class FilterCustomMutator {
  static isThereThisContent(filters, typeContent) {
    if (filters.type === 'block') {
      for (const condition of filters.conditions) {
        const result = FilterCustomMutator.isThereThisContent(condition, typeContent)
        if (result === true) {
          return true
        }
      }
    } else if (filters.typeCondition === typeContent) {
      return true
    }
    return false
  }

  static replaceContentValue(filters, typeContent, content) {
    if (filters.type === 'block') {
      for (const condition of filters.conditions) {
        FilterCustomMutator.replaceContentValue(condition, typeContent, content)
      }
    } else if (filters.typeCondition === typeContent) {
      for (const [key, value] of Object.entries(content)) {
        filters.item[key] = value
      }
    }
  }

  static replaceContentValueHoveredEvent(filters, hoveredEvent) {
    if (filters.type === 'block') {
      for (const condition of filters.conditions) {
        FilterCustomMutator.replaceContentValueHoveredEvent(condition, hoveredEvent)
      }
    } else if (filters.typeCondition === CustomFilteringFormContent.STRICT_VALUE_HOVERED_EVENT) {
      filters.item.value = (hoveredEvent === null) ? undefined : EventsUtils.getValueFromRootOrExt(hoveredEvent, filters.item.key)
    }
  }
}

export default FilterCustomMutator