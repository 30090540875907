<template>
  <div>
    <div class="grid grid-cols-2 gap-4">
      <div
        v-for="(field, index) in fields"
        :key="index"
        class="unit"
      >
        <label>{{ field.label }}</label>
        <select
          v-model="field.value"
          :multiple="field.allowMultiple"
          @change="(e) => onChangeFieldValue(e, field)"
        >
          <option
            v-for="(choice, idx) in field.choices"
            :key="idx"
            :value="choice.value"
          >
            {{ choice.label }}
          </option>
        </select>
      </div>
    </div>
    <CustomDate
      v-if="isCustomDateEnabled"
      v-model:date-tokens="customDataTokens"
      v-model:date-format="customDateFormat"
      :date-sample="sampleDate"
    />
    <ImportConfig
      @importconfig="onImportConfig"
      @exportconfig="onExportConfig"
    />
  </div>
</template>

<script>
export default {
  name: 'FileForm'
}
</script>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import ImportConfig from '@/components/universal/form/importExport.vue'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'
import utils from '@/libraries/utils.js'
import CustomDate from '@/components/universal/form/CustomDate.vue'

const store = useStore()
const dateFormats = [
  { label: 'MM/dd/yy HH:mm', value: '%m/%d/%Y %H:%M' },
  { label: 'dd/MM/yyyy HH:mm', value: '%d/%m/%Y %H:%M' },
  { label: 'yyyy-MM-dd HH:mm:ssX', value: '%Y-%m-%d %H:%M' },
  { label: 'yyyy', value: '%Y' },
  { label: 'custom', value: 'custom' }
]

const typesSeverity = [
  { label: 'Numerique', value: 'numeric'},
  { label: 'Alphanumérique', value: 'alphanumeric'}
]

const tablesSchema = computed(() => store.getters['file/tablesSchema'])
const dataTables = computed(() => Object.keys(tablesSchema.value).map(t => ({
  label: t,
  value: t
})))

const allSections = computed(() => {
  const results = []

  for (const [tableName, columns] of Object.entries(tablesSchema.value)) {
    for (const column of Object.keys(columns)) {
      const value = `${tableName}.${column}`
      results.push({ label: value, value: value })
    }
  }

  return results
})

const customDateFormat = ref('')
const customDataTokens = ref([])

const isCustomDateEnabled = ref(false)
const sampleDate = ref('')
const fields = ref([
  {
    id: 'tableContent',
    label: 'Contenu de table',
    value: '',
    choices: dataTables.value,
    allowMultiple: false
  },
  {
    id: 'severity',
    label: 'Sévérité',
    value: '',
    choices: allSections.value,
    allowMultiple: false
  },
  {
    id: 'sections',
    label: 'Catégories',
    value: [],
    choices: allSections.value,
    allowMultiple: true
  },
  {
    id: 'typeSeverity',
    label: 'Type de sévérité',
    value: '',
    choices: typesSeverity,
    allowMultiple: false
  },
  {
    id: 'label',
    label: 'Libellé',
    value: '',
    choices: allSections.value,
    allowMultiple: false
  },
  {
    id: 'date',
    label: 'Date',
    value: '',
    choices: allSections.value,
    allowMultiple: false,
    onChange: () => {
      setSampleDate()
    }
  },
  {
    id: 'code',
    label: 'Code',
    value: '',
    choices: allSections.value,
    allowMultiple: false
  },
  {
    id: 'formatDate',
    label: 'Format de date',
    value: '',
    choices: dateFormats,
    allowMultiple: false,
    onChange: () => {
      resetCustomDateSettings()

      const formatDateField = getFieldValue('formatDate')
      createTokensCustomDate()
      isCustomDateEnabled.value = formatDateField === 'custom'
    }
  }
])

function onChangeFieldValue(event, field) {
  const callBack = field.onChange || null
  CollaborativeUtils.onInput(store, event, field.value, true, callBack)
}

function getFieldValue(id) {
  const field = fields.value.find(f => f.id === id)
  return (field !== undefined) ? field.value : ''
}

function setFieldValue(id, value) {
  const field = fields.value.find(f => f.id === id)

  if (field !== undefined) {
    field.value = value
  }
}

function getConfig() {
  const config = {
    table: getFieldValue('tableContent'),
    asSection: getFieldValue('sections'),
    severity: getFieldValue('severity'),
    date: getFieldValue('date'),
    dateFormat: getFieldValue('formatDate'),
    defaultLabel: getFieldValue('label'),
    code: getFieldValue('code'),
    typeSeverity: getFieldValue('typeSeverity')
  }

  if (isCustomDateEnabled.value) {
    config.tokens = customDataTokens.value
    config.dateFormat = customDateFormat.value
  }
  return config
}

function resetCustomDateSettings() {
  customDateFormat.value = ''
  customDataTokens.value = []
}

function onImportConfig(config) {
  setFieldValue('tableContent', config.table)
  setFieldValue('sections', config.asSection)
  setFieldValue('severity', config.severity)
  setFieldValue('date', config.date)
  setFieldValue('formatDate', (config.tokens === undefined) ? config.dateFormat : 'custom')
  setFieldValue('label', config.defaultLabel)
  setFieldValue('code', config.code)
  setFieldValue('typeSeverity', config.typeSeverity)

  if (config.tokens) {
    setSampleDate()
    customDataTokens.value = config.tokens
    isCustomDateEnabled.value = true
  }
}

function setSampleDate() {
  resetCustomDateSettings()

  let dateFieldPart = getFieldValue('date').split('.')
  const selectedTable = dateFieldPart[0]
  const dateKey = dateFieldPart[1]

  if (tablesSchema.value[selectedTable] === undefined || tablesSchema.value[selectedTable][dateKey] === undefined) {
    return
  }
  sampleDate.value = tablesSchema.value[selectedTable][dateKey]
}

function createTokensCustomDate() {
  const tokens = sampleDate.value
  customDataTokens.value = tokens.split(/[\s*,:\/+T-]/).map(t => ({
    token: t,
    formatter: 'ignore'
  }))
}

function onExportConfig() {
  const config = getConfig()
  utils.downloadObjectAsJson(config, 'config')
}

defineExpose({
  getConfig: getConfig
})

</script>

<style scoped>
.unit {
  display: flex;
  flex-direction: column;
  padding-inline: 2%;
}
.unit > * {
  margin: 5px;
}
</style>