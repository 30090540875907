<template>
  <MovingItem
    ref="movingContainer"
    container-class="add-event-modal-generic-container"
    :ref-slot="refDialog"
    :z-index="1"
  >
    <div
      ref="modal"
      class="modal_exportgraph"
      @mousemove="sendCheckScroll"
      @mousedown="onMouseDownModal"
    >
      <div class="flex flex-col h-full">
        <div 
          ref="DisplayContainer"
          class="flex flex-col grow min-h-0"
        >
          <div
            class="flex flex-row justify-between"
          >
            <h3>Export Graph</h3>
            <!-- <CrossButton
              @close="exitModal"
            /> -->
          </div>
          <select
            v-model="idSelectedGraphType"
            @change="onChangeGraphType"
          >
            <option
              v-for="type in graphType"
              :key="type.id"
              :value="type.id"
            >
              {{ type.nom }}
            </option>
          </select>
          <div v-if="[2, 3].includes(selectedGraphType.id)">
            entrée 1:
            <select
              v-model="dim2"
            >
              <option
                v-for="extension in get_Extensions()"
                :key="extension"
              >
                {{ extension }}
              </option>
            </select>
            
            groupe (qualitative):
            <select
              v-model="dimGroupe"
            >
              <option 
                v-for="extension in ['', ...get_Extensions()]"
                :key="extension"
              >
                {{ extension }}
              </option>
            </select>
          </div>

          <svg  
            ref="svgContainer" 
            class="svg-container"
          >
            .
          </svg>
        </div>
      </div>

      <table class="predictions-table">
        <thead v-if="tablePreHeader">
          <tr>
            <th
              v-for="(head, index) in tablePreHeader"
              :key="index"
            >
              {{ head }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr 
            v-for="(prediction, index) in tablePredictions"
            :key="index"
          >
            <td
              v-for="(pred, indexPred) in prediction"
              :key="indexPred"
            >
              {{ pred }}
            </td>
          </tr>
        </tbody>
      </table>

      <OptionsExport
        :n-options="selectedGraphType.nOptions"
        :graph-type="selectedGraphType.commande"
        @raffraichir="raffraichi"
      />
      <div class="grid grid-cols-2 mt-5">
        <DesignButton 
          text="Export SVG"
          @click="(e) => exportGraph(e, 'svg')"
        />
        <DesignButton 
          text="Export PNG"
          @click="(e) => exportGraph(e, 'png')"
        />
      </div>
      <div class="grid grid-cols-2 mt-5">
        <DesignButton 
          text="Export CSV"
          @click="exportCSV()"
        />
        <div class="flex grid grid-cols-2">
          <label style="text-align: center">
            séparateur : 
          </label>
          <input
            v-model="separator"
            type="text"
            class="grow"
            name="separator"
            rows="1"
            placeholder=";"
            @input="(e) => onInputChange(e, separator)"
          >
        </div>
      </div>
      <div 
        class="grid grid-cols-2 mt-5"
      >
        <DesignButton 
          text="Export Table"
          @click="(e) => exportCSV('table')"
        />
      </div>
    </div>
  </MovingItem>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "../../../store/mutations-types.js";
import CrossButton from "@/components/Button.vue"
import utils from "@/libraries/utils.js"
import EyeFetch from "@/libraries/EyeFetch.js"
import DesignButton from '@/components/DesignButton.vue'
import MovingItem from "@/components/MovingItem.vue"
import OptionsExport from "./OptionsExportGraph.vue";
import AppContextChecker from "@/libraries/AppContextChecker.js";
import event from "@/store/modules/event/event.js";
import Sections from '@/components/Sections.vue';
import { formatGrouped, exportToCSV } from '../../borderLists/ListGraphs/PlotUtils_List.js'
import { json } from "d3"
import CollaborativeUtils from "@/libraries/collaborative/utils.js"

export default {
  name: "ModalDialog",
  components: {
    // CrossButton,
    DesignButton,
    MovingItem,
    OptionsExport,
  },
  data: () => {
    const graphType = [
      { id: 0, nom: "Kaplan Meier", nOptions: 2, commande: 'KM' },
      { id: 1, nom: "Cumulatif", nOptions: 0, commande: 'Cumul' },
      { id: 2, nom: "Histogramme", nOptions: 3, commande: 'HistoTrancheAge' },
      { id: 3, nom: "BoxPlot", nOptions: 0, commande: 'BoxPlot' },
    ]
    return {
      sectionName: "",
      idCounter: 0,
      isEditing: false,
      refDialog: null,
      graph_setting: {
        Format: 0,
        pvalue: true,
        t_filtre: -1,
        Title: '',
        Type: "KM",
        xlabel: '',
        ylabel: '',
        intervalle_de_confiance: false,
        ratio: false,
        t_filtre: -1,
        intervalle_de_confiance: false,
        all_pvalue: false,
        hazard_ratio: false,
        legendTitle: false,
        en_pourcentage: false,
      },
      graphType: graphType,
      selectedGraphType: graphType[0],
      svgElementExport: null,
      jsonElement: {},
      separator: ';',
      resizeObserver: null,
      idSelectedGraphType: graphType[0].id,
      tablePredictions: null,
      tablePreHeader: null,
      dim2: "",
      dimGroupe:"",
    };
  },
  computed: {
    ...mapGetters({
      Sections: "sections",
      allEvents: "event/common/allEvents",
      pieSelectedSections: "pie/pieSelectedSections",
      customSectionsConfig:'pie/customSectionsConfig'
    }),
  },
  watch: {
  },
  mounted() {
    this.initMovingObject()
    this.initResizeObserver();
    this.getGraphTool()
    this.selectedGraphType = this.graphType[0]
    this.idSelectedGraphType = this.graphType[0].id
    this.get_Extensions()
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      deleteEvent: 'event/edit/deleteEvent',
      deleteUserCache: 'deleteUserCache',
      getDataRepresentation: 'circle/getDataRepresentation',

    }),
    onMouseDownModal(event) {
      this.sendEvent({ event: event })
      if (['div', 'form', 'label'].includes(event.target.localName) === false) {
        event.stopPropagation()
      }
      this.collaborativeEventTreated()
    },
    initMovingObject() {
      this.refDialog = this.$refs.modal
      this.$refs.movingContainer.initialCoordinate(50, 50)
      this.$refs.movingContainer.moveItem()
      this.$refs.movingContainer.setOptions({
        share: true,
        resize: true,
      })
    },
    exitModal(event) {
      if (event) {
        this.sendEvent({ event })
      }
      this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
      this.$store.commit(`${mutationTypes.IS_EDIT_MODE}`, false)
      this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_TYPE}`, null)
      if (event) {
        this.collaborativeEventTreated()
      }
    },

    get_Extensions() {
      const event_0 = this.get_mesEvents()[0]
      return Object.keys(event_0['extension'])       
    },

    get_mesEvents() {
      let mesEvents = []

      if (AppContextChecker.isMergedEvents(this.$store)) {
        this.allEvents.forEach(event => {
          event.ranks.forEach(rank => {
            rank.events.forEach(rankEvent =>
              mesEvents.push(rankEvent)
            )
          })
        })
        mesEvents = mesEvents.map(item => {
          const replacement = this.Sections.find(repl => repl.id === item.idSection);
          return {
            ...item,
            idSection: replacement ? replacement.name : item.idSection
          };
        });
      }
      else {
        mesEvents = this.allEvents
      }
      return mesEvents
    },

    async getGraphTool() {
      let mesEvents = this.get_mesEvents()
      try {
        
        let dataGraph = mesEvents.map(({ Name, code, onsetDateTime, extension, idSection }) => ({
          id: extension.IdPatient,
          Date_Events: onsetDateTime,
          idSection: /*Name*/ idSection,
          code: code,
          dim2: extension[this.dim2],
          dimGroupe: extension[this.dimGroupe],

          // customSectionsConfig correspond au filtrage personnalisé
          // extension.StatutEtGroupe specifique à Chati, à généraliser
          StatutEtGroupe: this.customSectionsConfig || null ? extension.StatutEtGroupe : null,
          Age: extension.AgeJourExam || null,
          sexe: extension.Sexe || null
        }))

        const response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/services/graphtool_data`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            dataGraph: dataGraph,
            setting: this.graph_setting
          })
        })
        const res = await response.json()
        if (res) {
          const parser = new DOMParser()
          const svgDocument = parser.parseFromString(res.svg, 'image/svg+xml') // { svg: '<svg>' }
          this.jsonElement = res.distribution
          const svgElement = svgDocument.documentElement
        
          this.svgElementExport = svgDocument.documentElement
          try {
            res['tableStats']['tablePredictions'].length > 0 
            this.tablePredictions = res['tableStats']['tablePredictions']
            this.tablePreHeader   = res['tableStats']['tablePreHeader']
          } catch (error) {
            this.tablePredictions = []
            this.tablePreHeader = []
          }
          if (!svgElement.hasAttribute('viewBox')) {
            const width = svgElement.getAttribute('width') || 100;
            const height = svgElement.getAttribute('height') || 100
            svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
          }
          svgElement.removeAttribute('width');
          svgElement.removeAttribute('height');
          svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');

          this.$refs.svgContainer.innerHTML = '';
          this.$refs.svgContainer.appendChild(svgElement);

          this.scaleSVG();
        }
      } catch (error) {
        console.error('Error making the POST request:', error)
      }      
    },
    exportCSV(type='') {
      try {
        if (type == 'table' && this.tablePredictions.length > 0) {
          const json = this.tablePredictions.map(valueArray =>
            this.tablePreHeader.reduce((acc, label, index) => {
              acc[label] = valueArray[index];
              return acc;
            }, {})
          );
          // console.log(json);
          exportToCSV(json, "exportTable")
        }
        else {
          exportToCSV(this.jsonElement, this.selectedGraphType.nom)
        }
      } catch (error) {
        console.error(error)
      }
      return
    },
    exportGraph(event, format) {
      console.log('exportGraph ', format);
      let svgElement = this.svgElementExport
      if (!svgElement) {
        console.error('SVG element not found')
        return
      }

      const serializer = new XMLSerializer()
      const svgString = serializer.serializeToString(svgElement)
      let graphName = "graph"
      if (this.graph_setting.Title.length > 0)
        graphName = this.graph_setting.Title
      // Export as SVG
      if (format === 'svg') {
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
        const url = URL.createObjectURL(svgBlob)
        const link = document.createElement('a')
        link.href = url
        link.download = `${graphName}.svg`
        link.click()
        URL.revokeObjectURL(url)
      }
      // Export as PNG
      else if (format === 'png') {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const img = new Image()
        const svgBlob = new Blob([svgString], { type: 'image/svg+xml;charset=utf-8' })
        const url = URL.createObjectURL(svgBlob)
        const scaleFactor = window.devicePixelRatio || 1; // Use device pixel ratio for better resolution
        console.log(`scale factor ${scaleFactor}`)
        img.onload = () => {
          const viewBox = svgElement.getAttribute('viewBox')
          console.log('viewBox', viewBox ? true : false);

          if (viewBox) {

            const [x, y, width, height] = viewBox.split(' ').map(Number)
            canvas.width = width * scaleFactor
            canvas.height = height * scaleFactor
          } else {
            canvas.width = 1440 || (svgElement.clientWidth  || 800) * scaleFactor;
            canvas.height = 860 || (svgElement.clientHeight || 600) * scaleFactor;
          }
          ctx.scale(scaleFactor, scaleFactor);
          ctx.drawImage(img, 0, 0, canvas.width / scaleFactor, canvas.height / scaleFactor);
          URL.revokeObjectURL(url)

          canvas.toBlob((blob) => {
            const pngUrl = URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = pngUrl
            link.download = `${graphName}.png`
            link.click()
            URL.revokeObjectURL(pngUrl) // Cleanup
          }, 'image/png')
        }

        img.onerror = () => {
          console.error('Error loading SVG as image')
          URL.revokeObjectURL(url) // Cleanup
        }

        img.src = url
      } else {
        console.error('Unsupported format:', format)
      }
    },
  
    raffraichi(data) {
      this.graph_setting = data
      this.getGraphTool()
    },
    initResizeObserver() {
      const container = this.$refs.svgContainer;
      this.resizeObserver = new ResizeObserver(() => {
        this.scaleSVG();
      });
      this.resizeObserver.observe(container);
    },
    renderSVG() {
      const parser = new DOMParser();
      const svgDocument = parser.parseFromString('<svg ...>', 'image/svg+xml');
      const svgElement = svgDocument.documentElement;

      const bbox = svgElement.getBBox();
      svgElement.setAttribute('viewBox', `0 0 ${bbox.width} ${bbox.height}`);

      svgElement.removeAttribute('width');
      svgElement.removeAttribute('height');
      svgElement.setAttribute('preserveAspectRatio', 'xMidYMid meet');
      this.$refs.svgContainer.innerHTML = '';
      this.$refs.svgContainer.appendChild(svgElement);
      this.scaleSVG();
    },
    scaleSVG() {
      const container = this.$refs.svgContainer;
      const rect = container.getBoundingClientRect();
      const svg = container.querySelector('svg');

      if (svg) {
        svg.style.width = `${rect.width}px`;
        svg.style.height = `${rect.height}px`;
      }
    },

    onChangeGraphType(event) {
      CollaborativeUtils.onInput(this.$store, event, this.idSelectedGraphType, true, () => {
        this.selectedGraphType = this.graphType.find(type => type.id === this.idSelectedGraphType)
        this.raffraichi({
          Title: '',
          xlabel: '',
          ylabel: '',
          Format: 0,
          Type: this.selectedGraphType.commande,
          pvalue: true,
          n: 4,
          age_range: 10,
          t_filtre: -1,
          intervalle_de_confiance: false,
          all_pvalue: false,
          hazard_ratio: false,
          legendTitle: false,
          tableauType: 'tpred',
          en_pourcentage:false,
        })
      })
    },
    onInput(...params) {
      CollaborativeUtils.onInput(...[this.$store, ...params])
    },
    sendCheckScroll(e) {
      utils.sendCheckScroll(e, this.$store)
    }
  },
}
</script>

<style scoped>
.add-event-modal-generic-container{
  position: fixed;
}
.modal_exportgraph {
  flex-direction: column;
  background-color: var(--color-bg-1);
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  width: 50vw;
  height: 50vh;
  min-width: 30vw;
  min-height: 50vh;
  resize: both;
  overflow: auto;
  color: var(--color-text);
  padding: 12px;
}

.input-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

.input_wrapper {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
}
.modal_form {
  display: flex;
  flex-direction: column;
}

/* .modal_custom button:hover {
background: rgb(86, 97, 109);
transition-duration: 500ms;
} */
.modal_custom button:nth-child(1) {
  margin-right: 2px;
}
.modal_custom button:nth-child(2) {
  margin-left: 2px;
}
.button_wrapper {
  display: flex;
  margin-top: 20px;
}
textarea {
  margin-bottom: 15px;
  resize: vertical;
}

dialog::backdrop {
  background: linear-gradient(90deg, navy,rgb(1, 1, 47) );
  opacity: 0.1;
}

#suggestions-modal {
  margin-top: 20px;
  margin-inline: 5px;
}

.suggestion {
  cursor: pointer;
}

.error-modal {
  color: red;
}

/* .insert-event-recurrence-choose-dates { */
input:invalid {
  border: red solid 1px;
}
.svg-container {
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: hidden; /* Avoid unwanted scrolling */
}

.svg-container > svg {
  display: block; /* Remove inline space */
}

table {
  border: 1px solid var(--color-text);
  border-collapse: collapse;
}
th, td {
  border-right: 1px solid var(--color-text);
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 2px;
  padding-bottom: 2px;
}
tr > th:last-child, td:last-child {
  border-right: 0px;
}
tr {
  border-bottom: 1px solid var(--color-text);
}
tbody > tr:last-child {
  border-bottom: 0px;
}
</style>