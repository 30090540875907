<template>
  <div class="flex flex-col">
    <label>
      Minimum
      <input
        v-model="config.min"
        @input="(e) => onInput(e, config.min, false)"
      >
    </label>
    <label>
      Maximum
      <input
        v-model="config.max"
        @input="(e) => onInput(e, config.max, false)"
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomSeveritiesBoundaries'
}
</script>

<script setup>
import { useStore } from 'vuex'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const config = defineModel({
  type: Object,
  required: true
})

if (config.value.min === undefined) {
  config.value.min = 0
}

if (config.value.max === undefined) {
  config.value.max = 0
}

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])
</script>