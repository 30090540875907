import CustomFilteringFormContent from '../enums/customFilteringFormContent.js'
import EventsUtils from '../libraries/eventsUtils.js'

export default {
  [CustomFilteringFormContent.STRICT_VALUE]: {
    template: (config) => ({
      key: config.key || '',
      value: config.value || ''
    }),
    checker: (event, filters) => {
      const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
      if (value === undefined) {
        return false
      }
      return value == filters.item.value 
    },
    labeller: (filters) => {
      return `${filters.item.key} = ${filters.item.value}`
    }
  },

  [CustomFilteringFormContent.STRICT_VALUE_HOVERED_EVENT]: {
    template: (config) => ({
      key: config.key || '',
      value: config.value || undefined
    }),
    checker: (event, filters) => {
      const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
      if (value === undefined) {
        return false
      }
      return value == filters.item.value 
    },
    labeller: (filters) => {
      return `${filters.item.key} = ${filters.item.value}`
    }
  },

  [CustomFilteringFormContent.EXCEPT_VALUE]: {
    template: (config) => ({
      key: config.key || '',
      value: config.value || ''
    }),
    checker: (event, filters) => {
      const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
      if (value === undefined) {
        return false
      }
      return value != filters.item.value 
    },
    labeller: (filters) => {
      return `${filters.item.key} ≠ ${filters.item.value}`
    }
  },

  [CustomFilteringFormContent.GREATER_THAN]: {
    template: (config) => ({
      key: config.key || '',
      lowerBound: config.lowerBound || ''
    }),
    checker: (event, filters) => {
      const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
      if (value === undefined) {
        return false
      }
      return value > filters.item.lowerBound
    },
    labeller: (filters) => {
      return `${filters.item.key} > ${filters.item.lowerBound}`
    }
  },

  [CustomFilteringFormContent.SMALLER_THAN]: {
    template: (config) => ({
      key: config.key || '',
      upperBound: config.upperBound || ''
    }),
    checker: (event, filters) => {
      const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
      if (value === undefined) {
        return false
      }
      return value < filters.item.upperBound
    },
    labeller: (filters) => {
      return `${filters.item.key} < ${filters.item.upperBound}`
    }
  },

  [CustomFilteringFormContent.BETWEEN]: {
    template: (config) => ({
      key: config.key || '',
      lowerBound: config.lowerBound || '',
      upperBound: config.upperBound || ''
    }),
    checker: (event, filters) => {
      const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
      if (value === undefined) {
        return false
      }
      return value > filters.item.lowerBound && value < filters.item.upperBound
    },
    labeller: (filters) => {
      return `${filters.item.lowerBound} < ${filters.item.key} < ${filters.item.upperBound}`
    }
  },

  [CustomFilteringFormContent.LIKE]: {
    template: (config) => ({
      key: config.key || '',
      value: config.value || ''
    }),
    checker: (event, filters) => {
      const value = EventsUtils.getValueFromRootOrExt(event, filters.item.key)
      if (value === undefined) {
        return false
      }
      if (typeof value === 'string') {
        return value.toLowerCase().includes(filters.item.value.toLowerCase())
      }
      return value?.includes(filters.item.value)
    },
    labeller: (filters) => {
      return `${filters.item.key} ≈ ${filters.item.value}`
    }
  }
}