import * as mutationTypes from '@/store/mutations-types.js'
//import ErrorManager from '@/libraries/ErrorManager.js'
//import EyeFetch from '@/libraries/EyeFetch.js'

const getDefaultState = () => {
  return {
    hierarchy: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    hierarchy: state => state.hierarchy
  },
  mutations: {
    [mutationTypes.SET_FILE_HIERARCHY] (state, payload) {
      state.hierarchy = payload
    }
  },
  actions: {
    /**
     * Permet de remettre le state du module à sa valeur par défaut
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    }
  }
}