<template>
  <div class="container-file-tile">
    <span>
      <strong>{{ name }}</strong>
    </span>
    <div class="mt-4">
      <label class="flex flex-col">
        Orientation du contenu
        <select
          v-model="typeContent"
          class="mt-2"
        >
          <option
            v-for="(content, index) in typesContent"
            :key="index"
            :value="content.value"
          >
            {{ content.label }}
          </option>
        </select>
      </label>
    </div>
    <div class="button_container mt-4">
      <div class="mr-2">
        <DesignButton
          text="Supprimer"
          @clicked="removeFile"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import DesignButton from "@/components/DesignButton.vue"

const typesContent = [
  { label: 'Horizontal', value: 'horizontal'},
  { label: 'Vertical', value: 'vertical'}
]

const typeContent = defineModel({
  type: String,
  required: true
})

defineProps({
  name: {
    type: String,
    required: true
  }
})

const emit = defineEmits(['removefile'])

function removeFile() {
  emit('removefile')
}
</script>

<style scoped>
.container-file-tile {
  border: solid 1px var(--c-gray-2);
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}
</style>