<template>
  <div>
    <div class="tooltip-event-header">
      <p class="tooltip_title">
        {{ text }}
      </p>
      <p class="tooltip_date">
        {{ conditionDate }}
      </p>
      <p class="annexes">
        Section: {{ sectionName }}
        <br>
        Sévérité: {{ severity }}
      </p>
      <br>
      <a
        href="#"
        class="show-associated"
        @click="showAssociatedEventsCircle"
      >
        Stats Associées
      </a>
    </div>
    <div
      ref="tooltip-event-note-container"
      class="tooltip-event-note-container"
    >
      <p
        v-if="note"
        class="tooltip-note"
      >
        {{ note }}
      </p>
      <div
        v-if="reportsToDisplay.length > 0"
        class="mb-6"
      >
        <p class="mb-4">
          Liens:
        </p>
        <div
          v-for="(report, index) in reportsToDisplay"
          :key="index"
          class="mb-4"
          @mouseover="(e) => onOverReport(e, report)"
          @mouseleave="(e) => onLeaveReport(e, report)"
          @click="(e) => onClickReport(e, report)"
        >
          <p>
            {{ (report.description.length === 0) ? 'Pas de description' : report.description }}
          </p>
        </div>
      </div>
      <div
        v-for="(extension, key) in tooltipData.extension"
        :key="key"
        class="tooltip-note mb-2"
      >
        <div v-if="typeof extension === 'object' && extension !== null && extension.value !== undefined">
          <div v-if="key === 'RSS'">
            <p>
              <span class="bold">{{ extension.label }}: </span>
              <a
                href="#"
                class="show-associated"
                @click="focusRSS"
                @mouseover="onOverIdRSS"
                @mouseleave="onLeaveIdRSS"
              >
                {{ extension.value }}
              </a>
            </p>
          </div>
          <div v-else-if="key === 'RUM'">
            <p>
              <span class="bold">{{ extension.label }}:</span>
              <a
                v-for="(RUM, idx) in extension.value"
                :key="idx"
                href="#"
                class="show-associated"
                @mouseover="(e) => onOverIdRUM(e, RUM)"
                @mouseleave="onLeaveIdRSS"
                @click="(e) => focusRUM(e, RUM)"
              >
                {{ RUM }}
              </a>
            </p>
          </div>
          <div v-else>
            <p>
              <span class="bold">{{ extension.label }}:</span> {{ `${extension.value !== '' ? extension.value : 'n/a'}` }}
            </p>
          </div>
        </div>
        <div v-else>
          <p><span class="bold">{{ key }}:</span> {{ extension }}</p>
        </div>
      </div>
      <div>
        <PatientNote
          v-for="(patientNote, index) in filteredPatientNotes"
          :key="index"
          :note="patientNote"
          @mouseover="(e) => onMouseOverPatientNote(e, patientNote)"
          @mouseleave="onMouseLeavePatientNote"
        />
      </div>
    </div>
    <GraphToolTipContent 
      :tooltip-data="tooltipData"
    />
  </div>
</template>

<script>

import * as d3 from 'd3'
import utils from '@/libraries/utils.js'
import { mapActions, mapGetters } from 'vuex'
import * as mutationTypes from '@/store/mutations-types'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import * as eventsTypes from "@/shared/enums/eventsTypes.js"
import GraphToolTipContent from './GraphToolTipContent.vue'
import PatientNote from '@/components/patientNotes/patientNote.vue'
import CohortUtils from '@/libraries/cohort/Utils.js'

export default {
  name: 'TooltipContentPatientEvent',
  components: {
    PatientNote,
    GraphToolTipContent
  },
  props: {
    tooltipData: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    filteredPatientNotes: []
  }),
  computed: {
    ...mapGetters({
      events: "event/common/events",
      patientNotes: 'patient/patientNotes',
      reports: 'event/report/reports',
      memorizedEvents: "event/common/memorizedEvents"
    }),
    /**
     * Titre de la tooltip
     * @type {String}
     */
    text() {
      return this.tooltipData.defaultLabel
        ? `[${this.tooltipData.code}] ${this.tooltipData.defaultLabel}`
        : `${this.tooltipData.code} non classés par Eyediag`;
    },
    /**
     * Date à laquelle s'est produite l'événement
     * @type {String}
     */
    conditionDate() {
      const formatDate = d3.timeFormat("%A %d %B %Y");
      return formatDate(d3.isoParse(this.tooltipData.onsetDateTime));
    },
    /**
     * Nom de la section dans laquelle se trouve l'événement
     * @type {String}
     */
    sectionName() {
      return this.tooltipData.Name || 'n/a';
    },
    /**
     * Sévérité de l'événement
     * @type {Number}
     */
    severity() {
      return this.tooltipData.severity || 'n/a';
    },
    /**
     * Note associée avec l'événement
     * @type {String}
     */
    note() {
      return this.tooltipData.note
    },
    reportsToDisplay() {
      if (this.tooltipData.report === undefined) {
        return []
      }
      return this.reports.filter(r => this.tooltipData.report.includes(r.id))
    }
  },
  mounted() {
    this.initScrollEmitter()
    // this.filterPatientNote()
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      getAllPatientNotes: 'patient/getAllPatientNotes'
    }),
    /**
     * Permet de contrôler la position de la souris à l'intérieur de la tooltip puis de l'envoyer aux autres participants de la session collaborative afin d'engendrer un scroll sur leur affichage si la zone où se trouve la souris du présentateur n'est pas visible
     * @method
     * @public
     */
    initScrollEmitter() {
      d3.select(this.$refs['tooltip-event-note-container'])
        .on('mousemove', (event) => {
          utils.sendCheckScroll(event, this.$store)
        })
    },
    /**
     * Cette fonction permet d'initier l'affichage du cercle des événements associés à l'événement dont le contenu est affiché dans la tooltip
     * @param {Event} event Evénement fournit par le listener
     */
    async showAssociatedEventsCircle(event) {
      this.sendEvent({ event: event })
      event.preventDefault()
      this.$store.commit(`refCircle/score/${mutationTypes.DISABLE_SCORE}`, null)
      const promise = this.$store.dispatch('refCircle/associatedEvents/getAssociatedEvents', this.tooltipData, {root: true})
      this.$store.commit(`refCircle/associatedEvents/${mutationTypes.UPDATE_SRC_EVENT_ASSOCIATED}`, this.tooltipData)

      await promise
      this.collaborativeEventTreated()
    },
    onOverIdRSS(event) {
      this.sendEvent({ event: event })
      const eventsRSS = this.events.filter((e) => e.extension.RSS.value === this.tooltipData.extension.RSS.value)
      const dpEvent = eventsRSS.find((e) => e.extension.typeDiagnostique.value === eventsTypes.pmsiTypeDiag.DP)
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: dpEvent,
        dest: eventsRSS.filter(e => utils.differentEventOrManyLocation(dpEvent, e))
      })
      this.collaborativeEventTreated()
    },
    onLeaveIdRSS(event) {
      this.sendEvent({ event: event })
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: {},
        dest: []
      })
      this.collaborativeEventTreated()
    },
    focusRSS(event) {
      CohortUtils.focusOn(event, this.$store, {
        focusContent: eyediagMode.populationalOptions.focusContent.RSS,
        focusParams: {
          RSS: this.tooltipData.extension.RSS.value
        }
      })
    },
    onOverIdRUM(event, idRum) {
      this.sendEvent({ event: event })
      
      const eventsRUM = this.events.filter((e) => e.extension.RUM.value.includes(idRum))
      const dpEvent = eventsRUM.find((e) => e.extension.typeDiagnostique.value === eventsTypes.pmsiTypeDiag.DP)
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: dpEvent,
        dest: eventsRUM.filter(e => utils.differentEventOrManyLocation(dpEvent, e))
      })
      this.collaborativeEventTreated()
    },
    focusRUM(event, idRum) {
      CohortUtils.focusOn(event, this.$store, {
        focusContent: eyediagMode.populationalOptions.focusContent.RUM,
        focusParams: {
          RSS: this.tooltipData.extension.RSS.value,
          idRum: idRum,
        }
      })
    },
    async filterPatientNote() {
      if (this.patientNotes.length === 0) {
        await this.getAllPatientNotes()
      }
      for (const patientNote of this.patientNotes) {
        if (this.tooltipData.extension.patientNotes.includes(patientNote.Id)) {
          this.filteredPatientNotes.push(patientNote)
        }
      }
    },
    onMouseOverPatientNote(event, patientNote) {
      this.sendEvent({event: event})

      if (patientNote.State) {
        const ids = patientNote.State.event.common.memorizedEvents.map(e => e.id)
        let destEvent = this.events.filter(e => ids.includes(e.id))
        const src = destEvent.find(e => e.id === this.tooltipData.id)
        
        if (src) {
          destEvent = destEvent.filter(e => e.id !== src.id)
          this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
            src: src,
            dest: destEvent
          })
        }
      }
      this.collaborativeEventTreated()
    },
    onMouseLeavePatientNote(event) {
      this.sendEvent({event: event})
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: {},
        dest: []
      })
      this.collaborativeEventTreated()
    },

    onOverReport(event, report) {
      this.sendEvent({event: event})
      const dest = this.events.filter(e => utils.differentEventOrManyLocation(this.tooltipData, e) && e.report !== undefined && e.report.includes(report.id))
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: this.tooltipData,
        dest: dest
      })
      this.collaborativeEventTreated()
    },

    onLeaveReport(event) {
      this.sendEvent({event: event})
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: {},
        dest: []
      })
      this.collaborativeEventTreated()
    },

    async onClickReport(event, report) {
      this.sendEvent({event: event})
      const reportEvents = this.events.filter(e => e.report !== undefined && e.report.includes(report.id))

      for (const selected of this.memorizedEvents) {
        this.$store.commit(`event/common/${mutationTypes.CHANGE_STATE_MEMORIZED_EVENT}`, selected)
      }
      for (const reportEvent of reportEvents) {
        this.$store.commit(`event/common/${mutationTypes.CHANGE_STATE_MEMORIZED_EVENT}`, reportEvent)
      }
      this.$store.commit(`event/report/${mutationTypes.SET_ID_SELECTED_REPORT}`, report.id)
      this.$store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, this.events)

      this.collaborativeEventTreated()
    }
  }
}

</script>

<style scoped>
.show-associated {
  color: var(--c-gray-1);
  font-size: 1.3rem;
}
</style>