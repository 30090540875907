<template>
  <div>
    <ToggleSwitch
      v-model:status="isDataStrict"
      text="Données strictes"
    />
  </div>
</template>

<script setup>
import ToggleSwitch from '@/components/ToggleSwitch.vue'

const isDataStrict = defineModel({
  type: Boolean,
  required: true
})
</script>