<template>
  <div
    class="container-drag bg-indigo-500"
  >
    <div v-if="files.length === 0">
      <UploadTile
        @files="addFiles"
      />
    </div>

    <div
      v-else
      class="grid grid-cols-4 gap-4"
    >
      <FileTile
        v-for="(file, index) in files"
        :key="index"
        v-model="file.typeContent"
        :name="file.file.name"
        @removefile="onRemoveFile(index)"
      />
      <UploadTile
        @files="addFiles"
      />
    </div>

    <div
      v-if="files.length > 0"
      class="button_container mt-4"
    >
      <div class="mr-2">
        <DesignButton
          text="Continuer"
          @click="sendFiles"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadFiles"
}
</script>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import EyeFetch from '@/libraries/EyeFetch.js'

import FileTile from '@/components/universal/upload/FileTile.vue'
import UploadTile from '@/components/universal/upload/UploadTile.vue'
import DesignButton from "@/components/DesignButton.vue"

const store = useStore()

const files = ref([])
const emit = defineEmits(["next"])

function addFiles(newFiles) {
  for (const file of newFiles) {
    files.value.push({ typeContent: '', file: file })
  }
}

async function sendFiles(event) {
  if (event.isTrusted) {
    const data = new FormData()
    for (const file of files.value) {
      data.append("files", file.file)
      data.append("typeContents", file.typeContent)
    }

    try {
      await EyeFetch(store,
        `${process.env.VUE_APP_SERVER_BASE_URL}/universal/upload`,
        {
          method: "POST",
          credentials: 'include',
          headers: {
            'Content-type': 'multipart/form-data'
          },
          body: data
        }
      )
      store.dispatch('ws/sendEvent', { event: event })
    } catch (e) {
      console.error(e)
    }
  }

  await store.dispatch('file/getTablesSchema')
  emit("next")
  store.dispatch('ws/collaborativeEventTreated')
}

function onRemoveFile(index) {
  files.value = files.value.filter((file, i) => i !== index)
}
</script>

<style scoped>
.form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border: dashed 3px var(--c-circle-1-8);
  border-radius: 5px;
}
.button_container {
  display: flex;
  justify-content: center;
}
.container-drag {
  background: var(--color-shadow);
  flex-grow: 1;
  border-radius: 5px;
  padding: 1%;
  transition: 200ms;
}
.is-dragover {
  background: var(--c-gray-2);
  transition: 300ms;
}

span {
  margin: 0 auto;
}

span, span > * {
  font-size: 2rem;
}

.button_container > div {
  width: 10rem;
}
</style>