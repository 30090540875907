<template>
  <div class="flex flex-col">
    <label>
      % Minimum
      <input
        v-model="min"
        @input="(e) => onInput(e, min, 'min')"
      >
    </label>
    <label class="mt-2">
      % Maximum
      <input
        v-model="max"
        @input="(e) => onInput(e, max, 'max')"
      >
    </label>
  </div>
</template>

<script>
export default {
  name: 'CustomSeveritiesPercentage'
}
</script>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const min = ref(0)
const max = ref(0)

const config = defineModel({
  type: Object,
  required: true
})

function onInput(event, vModel, configKey) {
  CollaborativeUtils.onInput(store, event, vModel, false, () => {
    if (isNaN(vModel)) {
      config.value[configKey] = 0
    } else {
      config.value[configKey] = +vModel / 100
    }
  })
}

if (config.value.min === undefined) {
  config.value.min = 0
}

if (config.value.max === undefined) {
  config.value.max = 0
}
</script>