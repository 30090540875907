<template>
  <ListBase
    id="list-graphs"
    ref="list-base"
  >
    <template #content>
      <ShowStats />
      <DesignButton 
        text="Export de graphs"
        @click="getExportGraph"
      /> 
      
      <ExportGraphModal 
        v-if="ExportGraphOn"
      />
      <div
        ref="graphs"
        class="border-between-section border-0 border-dashed"
      >
        <div 
          v-if="isInNurseEntry"
          class="titre-chart"
        >
          <p class="tooltip_title">
            Suivi diabétologie
          </p>
          <div 
            ref="chart_Diabeto" 
            class="chart_Diabeto"
          />
        </div>
        
        <div class="titre-chart">
          <p class="tooltip_title">
            Valeur temps
          </p>
          <div 
            ref="chart_valParTemps" 
            class="chart_valParTemps"
          />
        </div>

        <div class="titre-chart">
          <p class="tooltip_title">
            Évenements par année
          </p>
          <p>
            des catégories sélectionnées
          </p>
          <div 
            ref="chart_Border" 
            class="chart_Border"
          />
        </div>

        <div class="titre-chart">
          <p class="tooltip_title">
            Évenements par tranche sélectionnée
          </p>
          <div 
            ref="chart_Pie" 
            class="chart_Pie"
          />
        </div>
      </div>
    </template>
  </ListBase>
</template>
if (AppContextChecker.isInUniversal(this) === true) {
<script>
import ListBase from '@/components/borderLists/ListBase.vue'
import * as d3 from "d3";
import { mapGetters, mapActions } from 'vuex'
import { countParTemps_parCat, pieChart_Section, EveSevParTemps_parCat, valParTemps } from './PlotUtils_List';
import Collapse from '@/components/Collapse.vue';
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import ExportGraphModal from "@/components/modal/exportGraph/exportGraphModal.vue"
import DesignButton from '@/components/DesignButton.vue'
import AppContextChecker from "@/libraries/AppContextChecker.js";
import ShowStats from './ShowStats.vue';
import event from '@/store/modules/event/event';
import store from '@/store/index.js';

export default {
  components: {
    ListBase,
    ExportGraphModal,
    DesignButton,
    ShowStats
  },
  data() {
    return {
      resizeObserver: null,
      borderListTypes: borderListTypes,
      svgContainer: null,
      ExportGraphOn:false,
    }
  },

  computed: {
    ...mapGetters({
      Sections: "sections",
      allEvents: "event/common/allEvents",
      unitPerCircle: "circle/unitPerCircle",
      periodUnit: "circle/periodUnit",
      pieSelectedSections: 'pie/pieSelectedSections',
      pieHoveredSections: "pie/pieHoveredSections",
      currentDisplayedBorderList: 'borderList/currentDisplayedBorderList',
      isInNurseEntry: 'isInNurseEntry'
    }),
  },
  watch: {
    pieSelectedSections() {
      if (this.resizeObserver == null) {
        this.initResizeObserver()
      }
      this.drawChart()
    },
    pieHoveredSections() {
      if (this.resizeObserver == null) {
        this.initResizeObserver()
      }
      this.drawChart()
    },
  },
  beforeUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }    
  },
  mounted() {
    this.svgContainer = this.$refs.svgContainer
  },
  methods:{
    ...mapActions({
      'sendEvent': 'ws/sendEvent',
      'collaborativeEventTreated': 'ws/collaborativeEventTreated'
    }),
    movePanel(...params) {
      if (this.resizeObserver == null) {
        this.initResizeObserver()
      }
      this.drawChart()

      this.$refs['list-base'].movePanel(...params)
    },
    get_cats(selections) {
      let res = []
      for (const selection of selections) {
        for (const select_cat of selection) {
          res.push(select_cat.id)
        }
      }
      return res
    },
    get_events(categories) {
      let res = []
      if (!categories) {
        categories = this.get_cats([this.pieSelectedSections, this.pieHoveredSections])
      }      
      this.allEvents.forEach(x => {
        categories.includes(x.idSection) ? res.push(x) : null
      })
      res = res.map(item => {
        const replacement = this.Sections.find(repl => repl.id === item.idSection);
        let sectionName = replacement ? replacement.name : item.idSection;
        sectionName = sectionName.replace(/[\/\\:]+/g, '') 
          .replace(/\s{2,}/g, ' ') 
          .trim();
        return {
          ...item,
          SectionName: sectionName,
          value: item[store.state.dataConfig.severity]
        };
      });
      
      return res.map(({ idSection, extension, SectionName, code, severity, onsetDateTime, id, defaultLabel,value }) =>
        ({
          id: extension.IdPatient || id,
          onsetDateTime: onsetDateTime,
          idSection: idSection,
          Section: SectionName,
          code: code,
          severity: severity,
          label: defaultLabel,
          value: value
        })
      )
    },
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        try {
          for (const entry of entries) {
            this.drawChart();
          }
        } catch (error) {
        }
      });
      if (this.$refs['graphs']) {
        this.resizeObserver.observe(this.$refs['graphs']);
      }
    },
    drawChart() {
      try {
        if (this.currentDisplayedBorderList == borderListTypes.LIST_GRAPHS ) {
          if (this.$refs.chart_Diabeto) {
            const chartContainer = this.$refs.chart_Diabeto;
            const filteredData = this.get_events()
            d3.select(chartContainer).select("svg").remove();
            if (chartContainer) {
              EveSevParTemps_parCat(filteredData, chartContainer);
            }
          }
          if (this.$refs.chart_Border) {
            const chartContainer = this.$refs.chart_Border;
            const filteredData = this.get_events()
            d3.select(chartContainer).select("svg").remove();
            if (chartContainer) {
              countParTemps_parCat(filteredData, chartContainer);
            }
          }
          if (this.$refs.chart_Pie) {
            const chartContainer = this.$refs.chart_Pie;
            const filteredData = this.get_events()

            d3.select(chartContainer).select("svg").remove();
            if (chartContainer) {
              pieChart_Section(filteredData, chartContainer);
            }
          }

          if (this.$refs.chart_valParTemps) {
            const chartContainer = this.$refs.chart_valParTemps;
            // const filteredData = this.allEvents.filter(x => x.code == this.tooltipData.code);
            const filteredData = this.get_events()

            d3.select(chartContainer).select("svg").remove();
            if (chartContainer) {
              valParTemps(filteredData, chartContainer);
            }
          }

        }
      } catch (error) {
        
      }
    },
    collapsing(open) {
      if (open) {
        if (this.resizeObserver == null) {
          this.initResizeObserver()
        }
        this.drawChart()
      } 
      if (Object.values(this.$refs).length == 1 && this.resizeObserver) {
        this.resizeObserver.disconnect();
        this.resizeObserver = null
      }
    },
    getExportGraph(event) {
      this.sendEvent({ event: event })
      this.ExportGraphOn = !this.ExportGraphOn
      this.collaborativeEventTreated()
    },
  },
}
</script>
<style scoped>
.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 2px;
}
.axis-label {
  font-size: 12px;
  font-family: Arial, sans-serif;
}
.titre-chart {
  margin-left: 10px ;margin-right: 10px; margin-top: 5px;
}
</style>