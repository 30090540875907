/**
 * Définit les types d'unités disponible pour les périodes de temps des cercles
 */
export default {
  "YEAR": 1,
  "SEMESTER": 2,
  "QUARTER": 3,
  "MONTH": 4,
  "WEEK": 5,
  "DAY": 6,
  "HOUR": 7,
  "MINUTE": 8,
  "SECOND": 9,
  "MILLISECOND": 10
}