<template>
  <div>
    <div class="mt-4">
      <label>
        Ajout par
        <select
          v-model="selectedChoice"
          @change="onChangeChoice"
        >
          <option
            v-for="choice in choices"
            :key="choice.type"
            :value="choice.type"
          >
            {{ choice.label }}
          </option>
        </select>
      </label>
    </div>
    <div
      v-if="componentOptions !== undefined"
      class="mt-4"
    >
      <component
        :is="componentOptions"
        v-model="choiceOptions"
      />
      <DesignButton
        text="Valider Config"
        class="mt-4"
        @click="onValidateAddSection"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, markRaw } from 'vue'
import { useStore } from 'vuex'
import TypeCreationSection from '@/shared/enums/typeCreationSection.js'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

import Combination from '@/components/borderLists/ListConfigSections/addSection/Combination.vue'
import Filtering from '@/components/borderLists/ListConfigSections/addSection/Filtering.vue'
import Hierarchy from '@/components/borderLists/ListConfigSections/addSection/Hierarchy.vue'
import ImportFile from '@/components/borderLists/ListConfigSections/addSection/ImportFile.vue'

import DesignButton from '@/components/DesignButton.vue'

import EyeFetch from '@/libraries/EyeFetch.js'

const store = useStore()

const choices = [
  {
    type: TypeCreationSection.HIERARCHY,
    label: 'Hiérarchie',
    component: markRaw(Hierarchy)
  },
  {
    type: TypeCreationSection.COMBINATION,
    label: 'Combinaison',
    component: markRaw(Combination)
  },
  {
    type: TypeCreationSection.FILTERING,
    label: 'Filtrage personnalisé',
    component: markRaw(Filtering)
  },
  {
    type: TypeCreationSection.IMPORT_FILE,
    label: 'Importer une configuration',
    component: markRaw(ImportFile)
  }
]
const selectedChoice = ref(null)
const choiceOptions = ref({})

const componentOptions = ref(undefined)

const emit = defineEmits(['newSections'])

function onChangeChoice(event) {
  CollaborativeUtils.onInput(store, event, selectedChoice.value, true, () => {
    choiceOptions.value = { type: selectedChoice.value }
    componentOptions.value = choices.find(c => c.type === selectedChoice.value)?.component
  })
}

async function onValidateAddSection(event) {
  store.dispatch('ws/sendEvent', { event: event })
  let newSections = []
  if ([TypeCreationSection.COMBINATION, TypeCreationSection.HIERARCHY].includes(choiceOptions.value.type)) {
    const customData = await store.dispatch('pie/getCustomSections', {
      extraData: { customSectionConfig: choiceOptions.value }
    })
    newSections = customData.sections
  } else {
    newSections = choiceOptions.value.sections
  }
  emit('newSections', newSections)
  store.dispatch('ws/collaborativeEventTreated')
}

</script>

<style scoped>

label {
  @apply flex flex-col;
}

label > * {
  @apply mt-2;
}
</style>