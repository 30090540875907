<template>
  <div class="mt-6">
    <div class="flex flex-col items-center">
      <p>
        {{ dateSample.length > 0 ? dateSample : "aucune sélection" }}
      </p>
      <p class="mt-4">
        {{ dateFormat ? dateFormat : "Format de date" }}
      </p>
    </div>
    <div class="mt-4">
      <div
        v-for="(token, index) in dateTokens"
        :key="index"
      >
        <label>
          Définir {{ token.token }}
          <select
            v-model="token.formatter"
            @change="(e) => createCustomDate(e, token)"
          >
            <option
              v-for="(option, i) in optionsDateFormat"
              :key="i"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </label>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const optionsDateFormat = [
  { label: 'jour', value: 'day', d3Flag: '%d' },
  { label: 'mois', value: 'month', d3Flag: '%m' },
  { label: 'année (4 Chiffres)', value: 'year4Digits', d3Flag: '%Y' },
  { label: 'année (2 Chiffres)', value: 'year2Digits', d3Flag: '%y' },
  { label: 'heure (24h)', value: 'hour24', d3Flag: '%H' },
  { label: 'heure (12h)', value: 'hour12', d3Flag: '%I' },
  { label: 'AM/PM', value: 'am-pm', d3Flag: '%p' },
  { label: 'minute', value: 'minute', d3Flag: '%M' },
  { label: 'seconde', value: 'second', d3Flag: '%S' },
  { label: 'ignorer', value: 'ignore', d3Flag: '' }
]

const props = defineProps({
  dateSample: {
    type: String,
    required: true
  }
})

const dateTokens = defineModel('dateTokens', {
  type: Array,
  required: true
})

const dateFormat = defineModel('dateFormat', {
  type: String,
  required: true
})

onMounted(() => {
  createFormatFromConfig()
})

function createCustomDate(event, token) {
  CollaborativeUtils.onInput(store, event, token.formatter, true, () => {
    createFormatFromConfig()
  })
}

function createFormatFromConfig() {
  let dateField = props.dateSample

  for (const token of dateTokens.value) {
    const formatter = optionsDateFormat.find(o => o.value === token.formatter)
    dateField = dateField.replace(
      token.token,
      formatter.d3Flag
    )
  }

  dateFormat.value = dateField
}

</script>