import * as mutationTypes from '@/store/mutations-types.js'

const getDefaultState = () => {
  return {
    noLongerDisplay: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    noLongerDisplay: state => state.noLongerDisplay
  },
  mutations: {
    [mutationTypes.SET_TIPS_NO_LONGER_DISPLAY] (state, payload) {
      state.noLongerDisplay = payload
    }
  },
  actions: {}
}
