<template>
  <GenericModal
    :id="id"
    :title="title"
    :buttons="buttons"
    :description="description"
    theme="success"
    @close="onCancel"
  >
    <div class="wrapper">
      <ul class="flex flex-wrap list-none">
        <li
          v-for="(column, index) in columnsObj"
          :key="index"
          :class="`${column.isClicked ? 'selected' : ''}`"
          @click="(e) => selectColumn(e, index)"
        >
          {{ column.name }}
        </li>
      </ul>
      <ToggleSwitch
        v-model:status="keepEmptiesSections"
        class="m-8"
        text="Inclure les sections vides"
      />
    </div>
  </GenericModal>
</template>

<script setup>
import GenericModal from "../modal/GenericModal.vue"
import { useStore } from 'vuex'
import * as mutationTypes from "@/store/mutations-types.js"
import reloadDataTypes from "@/shared/enums/reload_data_types.js"
import ToggleSwitch from "../ToggleSwitch.vue"
import { ref, computed, watch, onMounted } from 'vue'

const store = useStore()

const id = "modal-chose-section"
const description = "Choisir la colonne pour les sous catégories"
const title = 'Sous catégories'
let columnTmp = null

const buttons = ref([
  {
    label: "Valider",
    backgroundColorTheme: "success",
    color: "white",
    onClick: onConfirm,
  },
  {
    label: "Annuler",
    backgroundColorTheme: "error",
    color: "white",
    onClick: onCancel,
  },
])
const isColumnSelected = ref(false)
const columnsObj = ref(null)
const keepEmptiesSections  = ref(false)

const isChoseSectionModalOpen = computed(() => store.getters["isChoseSectionModalOpen"])
const columns = computed(() => store.getters["columnsFile"])

watch(isChoseSectionModalOpen, () => {
  if (isChoseSectionModalOpen.value) {
    const elem = document.getElementById(id)
    elem.show()
  }
})

onMounted(() => {
  if (columns.value) {
    columnsObj.value = columns.value.map((elt) => {
      const obj = {}
      obj.name = elt
      obj.isClicked = false
      return obj
    })
  }
})

function resetColumns() {
  columnsObj.value.forEach(elt => {
    elt.isClicked = false
  })
}

async function onConfirm(event) {
  event.preventDefault()
  if (!isColumnSelected.value) return
  store.dispatch('sendEvent', {event: event})

  const hierarchy = store.getters['file/hierarchy/hierarchy']
  hierarchy.push(columnTmp)
  store.commit(`file/hierarchy/${mutationTypes.SET_FILE_HIERARCHY}`, hierarchy)
  const customConfig = {
    type: TypeCreationSection.HIERARCHY,
    hierarchy: hierarchy,
    isDataStrict: !keepEmptiesSections.value
  }

  const customData = await store.dispatch('pie/getCustomSections', {
    extraData: { customSectionConfig: customConfig }
  })
  FilterCustom.setIdToSection(customData.sections)
  store.commit(`pie/${mutationTypes.SET_CUSTOM_SECTIONS_CONFIG}`, customData.sections)

  const elem = document.getElementById(id)
  resetColumns()
  elem.close()

  store.commit(`${mutationTypes.OPEN_COLUMNS_MODAL}`, false)
  
  store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: store.state.circle.unitPerCircle,
    periodUnit: store.state.circle.periodUnit,
    reloadDataTypes: reloadDataTypes.CATEGORISATION,
  })
  store.commit(`${mutationTypes.OPEN_COLUMNS_MODAL}`, false)
  store.dispatch('ws/collaborativeEventTreated')
}

function onCancel(event) {
  store.dispatch('ws/sendEvent', {event: event})
  const elem = document.getElementById(id)
  resetColumns()
  elem.close()
  store.commit(`${mutationTypes.OPEN_COLUMNS_MODAL}`, false)
  store.dispatch('ws/collaborativeEventTreated')
}

function selectColumn(event, index) {
  store.dispatch('ws/sendEvent', {event: event})
  isColumnSelected.value = true

  //On s'assure qu'il n'on puisse pas selection deux case en même temps.
  let toFalse = columnsObj.value.findIndex((elt) => elt.isClicked === true)
  columnTmp = event.target.innerText
  columnsObj.value[index].isClicked = true

  if (toFalse !== -1) columnsObj.value[toFalse].isClicked = false
  store.commit(`${mutationTypes.SET_AS_SECTION}`, columnTmp)
  store.dispatch('ws/collaborativeEventTreated')
}
</script>

<style scoped>
.wrapper {
  margin-bottom: 15px;
}
li {
  padding: 5px;
  margin: 5px;
  list-style-type: none;
  background: var(--c-gray-soft-1);
}
ul {
  columns: 3;
}
.selected {
  background: rgba(128, 128, 128, 0.8);
}
</style>
