<template>
  <div
    id="#container-drag"
    @dragend="handleUIState"
    @dragenter="handleUIState"
    @dragleave="handleUIState"
    @drop="handleUIState"
  >
    <div
      v-if="fileDragging === false"
      class="flex flex-col items-center"
    >
      <UploadIcon />
      <span>
        <input
          id="file"
          ref="inputFiles"
          type="file"
          name="file"
          accept=".csv, .tsv"
          class="inputfile"
          @change="(e) => saveFile(e, $refs['inputFiles'].files)"
        >
        <label for="file">
          Choisir un fichier
        </label> ou déposer un fichier
      </span>
    </div>

    <div v-else>
      <span
        class="pointer-events-none"
      >
        <em>Déposez le fichier pour l'ouvrir</em>
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import UploadIcon from '@/assets/icons/uploadIcon.vue'

const store = useStore()

const fileDragging = ref(false)
const inputFiles = ref(null)

const emit = defineEmits(['files'])

function saveFile(event, files) {
  store.dispatch('ws/sendEvent', {
    event: event,
    params: {
      text: 'dataset',
      change: true
    }
  })
  if (event.isTrusted) {
    emit('files', files)
  }
  store.dispatch('ws/collaborativeEventTreated')
}

function handleUIState(e) {
  e.preventDefault()
  e.stopPropagation()
  const drag_container = document.querySelector("#container-drag")

  if (e.type === "dragenter") {
    drag_container.classList.add("is-dragover")
    fileDragging.value = true
  } else if (e.type === 'drop') {
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      this.saveFile(e, e.dataTransfer.files[i])
    }
  } else {
    drag_container.classList.remove("is-dragover")
    fileDragging.value = false
  }
}
</script>

<style scoped>
.inputfile + label {
  font-weight: 700;
  color: var(--c-circle-1-1);
  display: inline-block;
  cursor: pointer;
}

.inputfile + label:hover {
  color: var(--color-shadow);
}

/* c'est juste pour des questions d'accessibilité qu'on n'a 0.1px et non 0*/
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
</style>