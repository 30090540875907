<template>
  <div class="table">
    <h2>{{ tableName }}</h2>
    <ul>
      <li
        v-for="(value, columnName) in columns"
        :key="columnName"
        class="column"
        @click="(event) => selectColumn(columnName, event)"
      >
        {{ columnName }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ForeignKeyTable'
}
</script>

<script setup>
import { defineProps, defineEmits } from 'vue'

const props = defineProps({
  tableName: {
    type: String,
    required: true
  },
  columns: {
    type: Object,
    required: true
  }
})

const emit = defineEmits(['columnselected'])

function selectColumn(columnName, event) {
  const rect = event.target.getBoundingClientRect()
  emit('columnselected', {
    tableName: props.tableName,
    columnName,
    coordinates: {
      x: rect.left + rect.width / 2,
      y: rect.top + rect.height / 2
    }
  })
}
</script>

<style scoped>
.table {
  border: 1px solid var(--color-text);
  border-radius: 10px;
  padding: 5px;
}

.column {
  cursor: pointer;
  margin: 5px 0;
}
</style>