<template>
  <div 
    id="ListeSix" 
    class="fixed right-0 flex flex-row"
  >
    <div
      id="border-list-action-container"
      class="self-end"
    >
      <div
        id="option-container"
        class="flex flex-col items-end"
      >
        <ListOptions />
        <ModeOptions />
        <AddPatientViews />
        <AddPatientNotes />
        <div
          id="arrow-container"
          class="flex flex-row items-center"
        >
          <div 
            id="display-NameList-container"
            class="mr-2"
          >
            <span
              id="displayNameList"
            />
          </div>
          <svg
            id="border-list-arrow-action"
            class="inline-block cursor-pointer"
            width="15"
            height="15"
            @click="onClickArrowButton"
          >
            <path
              id="border-list-arrow-action-path"
              :d="arrowPath"
              stroke="var(--color-text)"
              stroke-width="2"
              stroke-linecap="round"
              fill="none"
              stroke-linejoin="round" 
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="relative pointer-events-auto">
      <component
        :is="conf.component"
        v-for="conf in borderListConfig"
        :key="conf.type"
        :ref="(el) => setListRef(el, conf)"
      />
      <div 
        id="caroussel-container" 
        ref="caroussel-container" 
        class="absolute none justify-around items-center w-full overflow-hidden bottom-0"
        :class="{
          'flex': isDisplayedList,
          'hidden': !isDisplayedList
        }"
      >
        <div 
          id="list-caroussel" 
          ref="list-caroussel" 
          class="flex justify-between sticky"
        >
          <svg
            v-for="(caroussel, index) in carousselList"
            :id="caroussel.type"
            :key="index"
            width="12" 
            height="12"
            @mouseover="(e) => onMouseOverCarousselItem(e, caroussel)"
            @mouseleave="(e) => onMouseLeaveCarousselItem(e, caroussel)"
            @click="(e) => onMouseClickCarousselItem(e, caroussel)"
          >
            <circle 
              cx="6" 
              cy="6"
              r="5" 
              :fill="(currentDisplayedBorderList === caroussel.type) ? 'red' : 'none'" 
              stroke="var(--color-text)"
              stroke-width="1" 
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, markRaw } from 'vue'

import ListSelectedEvent from "@/components/borderLists/ListSelectedEvent/ListSelectedEvent.vue"
import ListTimeFilter from "@/components/borderLists/ListTimeFilter/ListTimeFilter.vue"
import ListChooseTypeEventsDisplayVue from '@/components/borderLists/ListChooseTypeEventsDisplay/ListChooseTypeEventsDisplay.vue'
import ListChooseAssemblyElements from "@/components/borderLists/ListChooseAssemblyElements/ListChooseAssemblyElements.vue"
import ListUnclassified from "@/components/borderLists/ListUnclassified/ListUnclassified.vue"
import ListEventsToAdd from "@/components/borderLists/ListEventsToAdd/ListEventsToAdd.vue"
import ListTop10 from "@/components/borderLists/ListTop10/ListTop10.vue"
import ListEnterHierarchy from "@/components/borderLists/ListEnterHierarchy/ListEnterHierarchy.vue"
import ListNurseEntry from "@/components/borderLists/ListNurseEntry/ListNurseEntry.vue"
import ListForceJoinCollab from "@/components/borderLists/ListForceJoinCollab/ListForceJoinCollab.vue"
import * as mutationTypes from "@/store/mutations-types"
import ListPatientNotes from "@/components/borderLists/ListPatientNotes/ListPatientNotes.vue"
import ListLegend from '@/components/borderLists/ListLegend/ListLegend.vue'
import ListGraphs from '@/components/borderLists/ListGraphs/ListGraphs.vue'
import ListMergeEvents from "@/components/borderLists/ListMergeEvents/ListMergeEvents.vue"
import ListCreateFiltering from '@/components/borderLists/ListCreateFiltering/ListCreateFiltering.vue'
import ListConfigSections from '@/components/borderLists/ListConfigSections/ListConfigSections.vue'

import borderListTypes from '@/enums/borderList/borderListTypes.js'
import ListPatientViews from "@/components/borderLists/ListPatientViews/ListPatientViews.vue"
import timeFilter from '@/enums/time_filter.js'
import { useStore } from 'vuex'
import * as d3 from "d3"
import AddPatientNotes from '@/components/patientNotes/AddPatientNotes.vue'
import ListOptions from '@/components/borderLists/ListOptions.vue'
import ModeOptions from '@/components/borderLists/modeOptions/ModeOptions.vue'
import AddPatientViews from "@/components/patientViews/AddPatientViews.vue"

import AppContextChecker from "@/libraries/AppContextChecker.js"

const store = useStore()

const borderListConfig = [
  {
    type: borderListTypes.LIST_MEMORIZED_EVENTS,
    component: markRaw(ListSelectedEvent),
    ref: null
  },
  {
    type: borderListTypes.LIST_TIME_FILTER,
    component: markRaw(ListTimeFilter),
    ref: null
  },
  {
    type: borderListTypes.LIST_CHOOSE_TYPE_EVENTS_DISPLAY,
    component: markRaw(ListChooseTypeEventsDisplayVue),
    ref: null
  },
  {
    type: borderListTypes.LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY,
    component: markRaw(ListChooseAssemblyElements),
    ref: null
  },
  {
    type: borderListTypes.LIST_UNCLASSIFIED,
    component: markRaw(ListUnclassified),
    ref: null
  },
  {
    type: borderListTypes.LIST_EVENTS_TO_ADD,
    component: markRaw(ListEventsToAdd),
    ref: null
  },
  {
    type: borderListTypes.LIST_TOP_10,
    component: markRaw(ListTop10),
    ref: null
  },
  {
    type: borderListTypes.LIST_ENTER_HIERARCHY,
    component: markRaw(ListEnterHierarchy),
    ref: null
  },
  {
    type: borderListTypes.LIST_NURSE_ENTRY,
    component: markRaw(ListNurseEntry),
    ref: null
  },
  {
    type: borderListTypes.LIST_FORCE_JOIN_COLLAB,
    component: markRaw(ListForceJoinCollab),
    ref: null
  },
  {
    type: borderListTypes.LIST_PATIENT_NOTES,
    component: markRaw(ListPatientNotes),
    ref: null
  },
  {
    type: borderListTypes.LIST_LEGEND,
    component: markRaw(ListLegend),
    ref: null
  },
  {
    type: borderListTypes.LIST_PATIENT_VIEWS,
    component: markRaw(ListPatientViews),
    ref: null
  },
  {
    type: borderListTypes.LIST_MERGE_EVENTS,
    component: markRaw(ListMergeEvents),
    ref: null
  },
  {
    type: borderListTypes.LIST_CREATE_FILTERING,
    component: markRaw(ListCreateFiltering),
    ref: null
  },
  {
    type: borderListTypes.LIST_CONFIG_SECTIONS,
    component: markRaw(ListConfigSections),
    ref: null
  },
  {
    type: borderListTypes.LIST_GRAPHS,
    component: markRaw(ListGraphs),
    ref: null
  }
]

const carousselList = ref([])

const isDisplayedList = computed(() => store.getters['borderList/isDisplayedList'])
const currentDisplayedBorderList = computed(() => store.getters['borderList/currentDisplayedBorderList'])
const isInNurseEntry = computed(() => store.getters['isInNurseEntry'])
const eventFilters = computed(() => store.getters['event/filtering/eventFilters'])

const arrowPath = computed(() => isDisplayedList.value
  ? 'M2 2 13 7.5 2 13'
  : 'M13 2 2 7.5 13 13'
)

/**
 * Affichage ou non des listes en fonction de la liste active + gestion du caroussel
 */
watch(currentDisplayedBorderList, watchCurrentDisplayedBorderList)

watch(isInNurseEntry, () => {
  setCarousselConfig()
})

onMounted(() => {
  setCarousselConfig()
  watchCurrentDisplayedBorderList(currentDisplayedBorderList.value, store.getters['borderList/oldDisplayedBorderList'])
})

function watchCurrentDisplayedBorderList(newList, oldList) {
  if (oldList) {
    borderListConfig.find(conf => conf.type === oldList)?.ref.movePanel(false)
  }

  if (newList) {
    //Paramètre time, utilisé seulement avec la liste LIST_TIME_FILTER. Conserver le temps déjà présent dans le store, si un temps avait déjà était définit avant
    store.dispatch('event/common/controlEvent', {isEnabled: true, typeEventslist: newList, time: (eventFilters.value.time) ? eventFilters.value.time.unit : timeFilter.WEEK})
    store.commit(`borderList/${mutationTypes.SET_OLD_DISPLAYED_BORDER_LIST}`, oldList)
    borderListConfig.find(conf => conf.type === newList)?.ref.movePanel(true)
  }
}

/**
 * Cette fonction est appelé lorsque l'utilisateur clique sur le bouton gérant la liste des événements mémorisés
 * @public
 */
function onClickArrowButton(event) {
  store.dispatch('ws/sendEvent', { event: event })
  if (currentDisplayedBorderList.value === null) {
    store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_MEMORIZED_EVENTS)
  } else {
    borderListConfig.find(conf => conf.type === currentDisplayedBorderList.value)?.ref.movePanel(!isDisplayedList.value)
  }
  store.dispatch('ws/collaborativeEventTreated')
}

function onMouseOverCarousselItem(event, d) {
  store.dispatch('ws/sendEvent', { event: event })
  d3.select('#displayNameList').text(d.label)
  d3.select(event.currentTarget)
    .select('circle')
    .attr('fill', 'red')
  store.dispatch('ws/collaborativeEventTreated')
}

function onMouseLeaveCarousselItem(event, d) {
  store.dispatch('ws/sendEvent', { event: event })
  if (d.type !== currentDisplayedBorderList.value) {
    d3.select(event.currentTarget)
      .select('circle')
      .attr('fill', 'none')
  }
  d3.select('#displayNameList').text('')
  store.dispatch('ws/collaborativeEventTreated')
}

function onMouseClickCarousselItem(event, d) {
  store.dispatch('ws/sendEvent', { event: event })
  store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, d.type)
  store.dispatch('ws/collaborativeEventTreated')
}

function setListRef(el, conf) {
  conf.ref = el
}

function setCarousselConfig() {
  const carousselListConfig = [
    { type: borderListTypes.LIST_MEMORIZED_EVENTS, label: 'Evenements mémorisés' },
    { type: borderListTypes.LIST_TIME_FILTER, label: 'Filtrage par temps', conditions: isInNurseEntry.value === false},
    { type: borderListTypes.LIST_CHOOSE_TYPE_EVENTS_DISPLAY, label: 'Types d\'événement', conditions: isInNurseEntry.value === false && AppContextChecker.otherThanPharmacienTypePatient(store) },
    { type: borderListTypes.LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY, label: 'Sauvegarder filtres'},
    { type: borderListTypes.LIST_TOP_10, label: 'Top 10', conditions: isInNurseEntry.value === false },
    { type: borderListTypes.LIST_PATIENT_NOTES, label: 'Notes'},
    { type: borderListTypes.LIST_PATIENT_VIEWS, label: 'Vues'},
    { type: borderListTypes.LIST_NURSE_ENTRY, label: 'Suivi Diabétologie', conditions: isInNurseEntry.value},
    { type: borderListTypes.LIST_CONFIG_SECTIONS, label: 'Conf sections'},
    { type: borderListTypes.LIST_GRAPHS, label: 'graphs' }
  ]
  carousselList.value = carousselListConfig.filter(options => options.conditions === undefined || options.conditions === true)
}
</script>

<style scoped>
#ListeSix{
  height:100vh;
  pointer-events: none;
}

#border-list-action-container {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: calc(100vh - 15px);
  margin-right: 15px;
  margin-bottom: 15px;
  width: 30px;
}

#displayNameList{
  width: 150px;
  position: absolute;
  bottom: 3px;
  right: 30px;
  justify-content: flex-end;
  display: flex;
}

#caroussel-container {
  height: 50px;
  background-color: var(--color-bg-1-list);
  z-index: 6;
}

#list-caroussel {
  bottom: 20px;
  width: 150px;
}
#option-container{
  width: 30px;
  pointer-events: auto;
}

#arrow-container{
  width: 30px;
  height: 20px;
}

#border-list-arrow-action-path {
  transition: all 1s ease;
}

</style>