<template>
  <div>
    <ListSections
      v-model="base"
    />
    <p
      class="mt-4 cursor-pointer underline underline-offset-2"
      @click="exportSections"
    >
      Exporter
    </p>
    <p
      class="mt-4 cursor-pointer underline underline-offset-2"
      @click="removeCustomSections"
    >
      Effacer
    </p>
    <DesignButton
      text="Valider Catégories"
      class="mt-4"
      @click="onValidateSections"
    />
  </div>
</template>

<script setup>
import ListSections from '@/components/borderLists/ListConfigSections/ListSections.vue'
import DesignButton from '@/components/DesignButton.vue'
import { ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import utils from '@/libraries/utils.js'
import FilterCustom from '@/shared/libraries/filterCustom.js'
import * as mutationTypes from '@/store/mutations-types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

const store = useStore()
const base = ref([])

const customSectionsConfig = computed(() => store.getters['pie/customSectionsConfig'])

watch(customSectionsConfig, () => {
  if (customSectionsConfig.value !== null) {
    base.value = customSectionsConfig.value
  }
})

onMounted(() => {
  if (customSectionsConfig.value !== null) {
    base.value = customSectionsConfig.value
  }
})

async function onValidateSections(event) {
  store.dispatch('ws/sendEvent', { event: event })
  FilterCustom.setIdToSection(base.value)
  store.commit(`pie/${mutationTypes.SET_CUSTOM_SECTIONS_CONFIG}`, base.value)
  await store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: store.getters['circle/unitPerCircle'],
    periodUnit: store.getters['circle/periodUnit'],
    reloadDataTypes: reloadDataTypes.CATEGORISATION
  })
  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}

async function removeCustomSections(event) {
  store.dispatch('ws/sendEvent', { event: event })
  base.value = []
  store.commit(`pie/${mutationTypes.SET_CUSTOM_SECTIONS_CONFIG}`, null)
  await store.dispatch('circle/getDataRepresentation', {
    unitPerCircle: store.getters['circle/unitPerCircle'],
    periodUnit: store.getters['circle/periodUnit'],
    reloadDataTypes: reloadDataTypes.CATEGORISATION
  })
  utils.onGlobalAnimationEnd(store, () => {
    store.dispatch('ws/collaborativeEventTreated')
  })
}

function exportSections() {
  utils.downloadObjectAsJson(base.value, 'eyediagSections')
}
</script>