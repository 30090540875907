<template>
  <div>
    <h1>Lier des tables</h1>
    <div class="tables">
      <TableComponent
        v-for="(columns, tableName) in tablesSchema"
        :key="tableName"
        :table-name="tableName"
        :columns="columns"
        @columnselected="onColumnSelected"
      />
    </div>

    <svg class="links">
      <line
        v-for="(link, index) in links"
        :key="index"
        :x1="link.fromX"
        :y1="link.fromY"
        :x2="link.toX"
        :y2="link.toY"
        stroke="var(--color-text)"
        stroke-width="2"
      />
    </svg>
    <DesignButton
      text="Continuer"
      @click="onContinue"
    />
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import TableComponent from '@/components/universal/foreignKey/Table.vue'
import DesignButton from '@/components/DesignButton.vue'
import * as mutationTypes from '@/store/mutations-types.js'

const store = useStore()

const selectedColumn = ref(null)
const links = ref([])

const tablesSchema = computed(() => store.getters['file/tablesSchema'])

const emit = defineEmits(['next'])

function onColumnSelected({ tableName, columnName, coordinates }) {
  if (!selectedColumn.value) {
    selectedColumn.value = { tableName, columnName, coordinates }
  } else {
    links.value.push({
      fromTable: selectedColumn.value.tableName,
      fromColumn: selectedColumn.value.columnName,
      fromX: selectedColumn.value.coordinates.x,
      fromY: selectedColumn.value.coordinates.y,
      toTable: tableName,
      toColumn: columnName,
      toX: coordinates.x,
      toY: coordinates.y
    });
    selectedColumn.value = null
  }
}

function sortTables() {
  const root = {}
  let tableHierarchy = {}
  let firstLevelHierarchy = []

  for (const tableName of Object.keys(tablesSchema.value)) {
    tableHierarchy[tableName] = {}
    firstLevelHierarchy.push(tableName)
  }

  for (const link of links.value) {
    tableHierarchy[link.fromTable][link.toTable] = {}
    firstLevelHierarchy = firstLevelHierarchy.filter(tableName => tableName !== link.toTable)
  }

  for (const [tableName, childrenTables] of Object.entries(tableHierarchy)) {
    for (const child of Object.keys(childrenTables)) {
      tableHierarchy[tableName][child] = tableHierarchy[child]
    }
  }

  for (const firstLevel of firstLevelHierarchy) {
    root[firstLevel] = tableHierarchy[firstLevel]
  }

  return root
}

function onContinue() {
  const hierarchyTables = sortTables()
  store.commit(`file/${mutationTypes.SET_TABLES_LINKS}`, links.value)
  store.commit(`file/${mutationTypes.SET_TABLES_HIERARCHY}`, hierarchyTables)
  emit('next')
}

</script>

<style scoped>
.tables {
  @apply mt-4;
  display: flex;
  gap: 20px;
}

.links {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>