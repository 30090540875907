<template>
  <div>
    <div class="flex flex-col border-0 border-dashed">
      <label
        class="mb-2"
      >
        <h3>
          Options
        </h3>
      </label>
    </div>
    <div class="flex flex-row">
      <input
        v-model="title"
        type="text"
        class="grow"
        name="title"
        rows="1"
        placeholder="Titre"
        @input="(e) => onInput(e, title, false)"
      >
    </div>
    <div class="grid grid-cols-2">
      <div class="flex flex-row">
        <input
          v-model="xlabel"
          type="text"
          class="grow"
          name="xlabel"
          rows="1"
          placeholder="xlabel"
          @input="(e) => onInput(e, xlabel, false)"
        >
      </div>
      <div class="flex flex-row">
        <input
          v-model="ylabel"
          type="text"
          class="grow"
          name="ylabel"
          rows="1"
          placeholder="ylabel"
          @input="(e) => onInput(e, ylabel, false)"
        >
      </div>
    </div>
    <div class="flex flex-row">
      <input
        v-model="legendTitle"
        type="text"
        class="grow"
        name="legendTitle"
        rows="1"
        placeholder="legendTitle"
        @input="(e) => onInput(e, ylabel, false)"
      >
    </div>
  </div>
  <div class="input-row">
    <div
      v-if="nOptions > 0"
      class="radio-group"
    >
      type:
      <label 
        v-for="type in nTypes" 
        :key="type.value" 
        class="radio-label"
      >
        <input
          v-model="selectedFormat"
          type="radio"
          :value="type.value"
          name="radio-nTypes"
          @change="(e) => onInput(e, selectedFormat, true)"
        >
        {{ type.label }}
      </label>
    </div>
  </div>
  <div class="input-row">
    <label>p-value:</label>
    <input 
      v-model="pvalue" 
      type="checkbox"
      @click="onClickCheckbox"
    >
    <div v-if="graphType == 'KM'">
      <label>intervalle de confiance:</label>
      <input 
        v-model="intervalle_de_confiance" 
        type="checkbox"
        @click="onClickCheckbox"
      >
    </div>
    <div v-if="graphType == 'HistoTrancheAge'">
      <label
        v-for="(choix, index) of [[false, 'en quantité'],
                                  [true, 'en proprtion']]"
        :key="index"
      >
        <input
          v-model="en_pourcentage"
          type="radio"
          :value="choix[0]"
          name="radio-nTypes"
          @change="(e) => onInput(e, selectedFormat, true)"
        >
        {{ choix[1] }}
      </label>
    </div>
  </div>
  <div 
    v-if="['KM', 'Cumul'].includes(graphType)"
    class="input-row"
  >
    <label>afficher toutes p-value:</label>
    <input 
      v-model="all_pvalue" 
      type="checkbox"
      @click="onClickCheckbox"
    >
    <label>afficher tout les hazard ratio:</label>
    <input 
      v-model="hazard_ratio" 
      type="checkbox"
      @click="onClickCheckbox"
    >
    <h3>
      tableau:
    </h3>
    <div>
      <label
        v-for="(tableau, index) of [['tpred', 'Predictions à t-jours'],
                                    ['HR', 'Hazard Ratios']]"
        :key="index"
      >
        <input
          v-model="tableauType"
          type="radio"
          :value="tableau[0]"
          name="radio-nTypes"
          @change="(e) => onInput(e, selectedFormat, true)"
        >
        {{ tableau[1] }}
      </label>
    </div>
  </div>
  <div 
    v-if="['HistoTrancheAge', 'BoxPlot'].includes(graphType)"
    class="input-row"
  >
    <label for="n">Nombre de tranches:</label>
    <input 
      v-model="n" 
      type="number" 
      min="1" 
      placeholder="Enter positive integer" 
      class="small-input"
      @input="(e) => onInput(e, n, false)"
    >
    
    <label for="tranche">Taille:</label>
    <input 
      v-model="tranche"
      type="number" 
      min="1" 
      placeholder="Enter tranche value" 
      class="small-input"
      @input="(e) => onInput(e, tranche, false)"
    >
  </div>

  <div 
    v-if="typeEntityContext == userRole.CH"
    class="input-row"
  >
    <label>Retirer les actes de revascularisation des n jours à partir du premier acte des Ish(+)Revasc(+)</label>
    <input
      v-model="t_filtre"
      type="text"
      class="grow"
      name="t_filtre"
      rows="1"
      placeholder="Retirer les actes de revascularisation des n jours à partir du premier acte"
      @input="(e) => onInput(e, t_filtre, false)"
    >
  </div>
  <DesignButton 
    text="Rafraichir"
    @click="raffraichi"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DesignButton from '@/components/DesignButton.vue'
import userRole from '@/shared/enums/userRole';

import CollaborativeUtils from '@/libraries/collaborative/utils.js'

export default {
  name: 'AddEventOptions',
  components: {
    DesignButton,
  },
  props:
  {
    graphType:{
      type: String,
      required: true, 
      default: "KM",
    },
    nOptions: {
      type: Number,
      required: true,
      default: 1,
    },
  },
  emits:
    ["raffraichir"],
  data: () => ({
    pvalue: true,
    title: "",
    selectedFormat: 0,
    t_filtre: -1,
    xlabel: '',
    ylabel:'',
    userRole: userRole,
    n: 4,
    tranche: 10,
    intervalle_de_confiance: false,
    all_pvalue: true,
    hazard_ratio: true,
    legendTitle: '',
    tableauType: "tpred",
    en_pourcentage: false,
  }),
  computed: {
    ...mapGetters({
      allEvents: 'event/common/allEvents',
      editEvent: 'event/edit/editingEventToAdd',
      typeEntityContext: 'user/typeEntityContext',

    }),
    nTypes() {
      return Array.from({ length: this.nOptions }, (_, index) => ({
        label: `${index + 1}`,
        value: index,
      }));
    },

  },
  watch: {
    graphType() {
      this.resetForm()
    }
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      sendEvent: 'ws/sendEvent',
    }),
    resetForm() {
      this.title = ""
      this.xlabel = ""
      this.ylabel = ""
      this.pvalue = true
      this.t_filtre = -1
      this.selectedFormat = 0
      this.all_pvalue = false
      this.hazard_ratio = false
    },
    raffraichi(event) {
      this.sendEvent({ event: event })
      let obj_res = {
        Format: this.selectedFormat,
        pvalue: this.pvalue,
        Type: this.graphType,
        t_filtre: this.t_filtre,
        Title: this.title.length > 0 ? this.title :false,
        xlabel: this.xlabel.length > 0 ? this.legendxlabelTitle :false,
        ylabel: this.ylabel.length > 0 ? this.ylab:false,
        intervalle_de_confiance: this.intervalle_de_confiance,
        all_pvalue: this.all_pvalue,
        hazard_ratio: this.hazard_ratio,
        legendTitle: this.legendTitle.length > 0 ? this.legendTitle : false,
        tableauType: this.tableauType,
        en_pourcentage: this.en_pourcentage,

      }
      if (['HistoTrancheAge', 'BoxPlot'].includes(this.graphType)) {
        obj_res['n'] = this.n
        obj_res['age_range'] = this.tranche
      }
      this.$emit(
        'raffraichir',
        obj_res
      )
      this.collaborativeEventTreated()
    },
    onInput(...params) {
      CollaborativeUtils.onInput(...[this.$store, ...params])
    },
    onClickCheckbox(event) {
      this.sendEvent({ event: event })
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
label {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}
.input-row {
  display: flex;
  gap: 10px;
  align-items: center;
}

</style>