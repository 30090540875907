import * as mutationTypes from '@/store/mutations-types.js'
import ErrorManager from '@/libraries/ErrorManager.js'
import EyeFetch from '@/libraries/EyeFetch.js'
import hierarchy from '@/store/modules/file/hierarchy.js'

const getDefaultState = () => {
  return {
    tablesSchema: {},
    tablesLinks: [],
    tablesHierarchy: {}
  }
}

export default {
  namespaced: true,
  modules: {
    hierarchy: hierarchy
  },
  state: getDefaultState(),
  getters: {
    tablesSchema: state => state.tablesSchema,
    tablesLinks: state => state.tablesLinks,
    tablesHierarchy: state => state.tablesHierarchy,
    tablesNames: state => Object.keys(state.tablesSchema)
  },
  mutations: {
    [mutationTypes.SET_TABLES_SCHEMA] (state, payload) {
      state.tablesSchema = payload
    },
    [mutationTypes.SET_TABLES_LINKS] (state, payload) {
      state.tablesLinks = payload
    },
    [mutationTypes.SET_TABLES_HIERARCHY] (state, payload) {
      state.tablesHierarchy = payload
    }
  },
  actions: {
    async resetState(context) {
      const promises = []
      const modulesToReset = []

      Object.assign(context.state, getDefaultState())

      modulesToReset.forEach(module => {
        promises.push(context.dispatch(`file/${module}/resetState`, null, {root: true}))
      })

      return Promise.all(promises) 
    },
    async getTablesSchema(context) {
      try {
        let response = await EyeFetch(this, `${process.env.VUE_APP_SERVER_BASE_URL}/universal/headers`, {
          method: 'GET',
          credentials: 'include'
        })

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'file/getTablesSchema', params: null})
          return
        }

        response = await response.json()
        context.commit(mutationTypes.SET_TABLES_SCHEMA, response.tablesSchema)
      } catch (e) {
        console.error(e)
        // ErrorManager.fetchError(e)
      }
    }
  }
}