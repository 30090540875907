<template>
  <div>
    <p>{{ `Min ${minSeverity}` }}</p>
    <p>{{ `Max ${maxSeverity}` }}</p>
    <div class="flex flex-col mt-4">
      <label>
        Type d'échelle
        <select v-model="section.config.typeScale">
          <option
            v-for="(scale, index) in scales"
            :key="index"
            :value="scale.value"
            @change="(e) => onInput(e, section.config.typeScale, true)"
          >
            {{ scale.label }}
          </option>
        </select>
      </label>
      <label>
        Type de bornes
        <select
          v-model="selectedConfig"
          @change="onChangeConfig"
        >
          <option
            v-for="(config, index) in configs"
            :key="index"
            :value="config.value"
          >
            {{ config.label }}
          </option>
        </select>
      </label>
    </div>
    <component
      :is="componentConfig"
      v-if="componentConfig !== null"
      v-model="section.config"
      class="mt-4"
    />
  </div>
</template>

<script>
export default {
  name: 'CustomSeveritiesForm'
}
</script>

<script setup>
import { markRaw, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'

import TypeConfigCustomSeverity from '@/shared/enums/typeConfigCustomSeverity.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'

import Percentage from '@/components/borderLists/ListConfigSections/configSeverities/percentage.vue'
import Boundaries from '@/components/borderLists/ListConfigSections/configSeverities/boundaries.vue'

import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const scales = [
  {
    label: 'Linéaire',
    value: eyediagMode.typeScale.LINEAR
  },
  {
    label: 'Racine',
    value: eyediagMode.typeScale.RACINECARRE
  },
  {
    label: 'Brut',
    value: eyediagMode.typeScale.BRUT
  },
]

const configs = [
  {
    label: 'Pourcentage',
    value: TypeConfigCustomSeverity.PERCENTAGE,
    component: markRaw(Percentage)
  },
  {
    label: 'Arbitraire',
    value: TypeConfigCustomSeverity.BOUNDARIES,
    component: markRaw(Boundaries)
  },
  {
    label: 'Ne pas définir',
    value: TypeConfigCustomSeverity.NONE,
    component: null
  },
]

const selectedConfig = ref(TypeConfigCustomSeverity.NONE)
const componentConfig = ref(null)

const section = defineModel({
  type: Object,
  required: true
})

const minSeverity = computed(() => {
  if ([null, undefined].includes(section.value.section.minSeverityValue)) {
    return 'n/a'
  } else {
    return section.value.section.minSeverityValue.toLocaleString()
  }
})

const maxSeverity = computed(() => {
  if ([null, undefined].includes(section.value.section.maxSeverityValue)) {
    return 'n/a'
  } else {
    return section.value.section.maxSeverityValue.toLocaleString()
  }
})

onMounted(() => {
  if (section.value.config.type) {
    const config = configs.find(c => c.value === section.value.config.type)
    selectedConfig.value = section.value.config.type
    componentConfig.value = config.component
  }
})

function onChangeConfig(event) {
  CollaborativeUtils.onInput(store, event, selectedConfig.value, true, () => {
    const config = configs.find(c => c.value === selectedConfig.value)
    section.value.config = { type: selectedConfig.value, typeScale: section.value.config.typeScale }
    componentConfig.value = config.component
  })
}

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])

</script>

<style scoped>
label {
  @apply flex flex-col mt-2;
}
</style>