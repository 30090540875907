<template>
  <div class="flex flex-row justify-between">
    <div>
      <div class="mt-2 flex flex-row">
        <div class="container-design-button mr-2">
          <label
            for="UniversalImportConfigfile"
          >
            <DesignButton
              text="Importer"
              class="design-button hidden"
              @click="onClickImport"
            />
            <input
              id="UniversalImportConfigfile"
              ref="universalImportConfigfile"
              type="file"
              name="file"
              accept=".json"
              class="inputfile hidden"
              @change="readConfigFromFile($refs['universalImportConfigfile'].files[0])"
            >
          </label>
        </div>
        <div class="container-design-button ml-2">
          <DesignButton
            text="Exporter"
            class="design-button"
            @clicked="onExportConfig"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import DesignButton from '@/components/DesignButton.vue'

const universalImportConfigfile = ref(null)

const emit = defineEmits(['importconfig', 'exportconfig']) 

function onClickImport() {
  universalImportConfigfile.value.click()
}

function onExportConfig() {
  emit('exportconfig')
}

async function readConfigFromFile(file) {
  const fileReader = new FileReader()
  fileReader.readAsText(file)

  let result = await new Promise((resolve, reject) => {
    fileReader.onload = () => resolve(fileReader.result)
    fileReader.onerror = (error) => reject(error)
  })
  result = JSON.parse(result)

  emit('importconfig', result)
}
</script>