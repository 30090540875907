<template>
  <div>
    <h1 style="color: white; margin: 20px">
      TUTORIELS:
    </h1>
    <div class="TutorielContainer">
      <Component1 />
      <Component6 />
      <Component2 />
      <Component3 />
      <Component4 />
      <Component5 />
    </div>
  </div>
</template>

<script>
import Component1 from "@/components/tutorial/homeItems/decouverte.vue"
import Component2 from "@/components/tutorial/homeItems/priseEnMain.vue"
import Component3 from "@/components/tutorial/homeItems/concept.vue"
import Component4 from "@/components/tutorial/homeItems/1.vue"
import Component5 from "@/components/tutorial/homeItems/2.vue"
import Component6 from "@/components/tutorial/homeItems/3.vue"
// import Component3 from "@/components/tutorial/homeItems/score.vue"
// import Component4 from "@/components/tutorial/homeItems/filtre.vue"
// import Component5 from "@/components/tutorial/homeItems/cohorte.vue"
// import Component6 from "@/components/tutorial/homeItems/antécédantsFamiliaux.vue"
// import Component7 from "@/components/tutorial/homeItems/liensComptesRendus.vue"

export default {
  name: "TutorialView",
  components: {
    Component1,
    Component2,
    Component3,
    Component4,
    Component5,
    Component6,
    // Component7
  }
};
</script>

<style scoped>
.TutorielContainer {
    display: grid;
    grid-template-columns: 26vw 26vw 26vw;
    flex-wrap: wrap;
    grid-column-gap: 9vw;
    grid-row-gap: 10vh;
    margin: 2vw;
}
</style>